import React from "react";
import PostJob from "../../features/job/components/PostJob";
import SideMenu from "../../shared/SideMenu/SideMenu";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";

const PostJobPage = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    // <div className="dashboard_content_wrapper" style={{ marginTop: '-35px' }}>
    //   <div className="dashboard dashboard_wrapper pr30 pr0-xl">
    //     <SideMenu />
    //     <div className="dashboard__main" style={{ marginTop: '50px' }}>
    //       <div
    //         className="dashboard__content hover-bgc-color"
    //         style={{ width: '1100px', height: '1050px' }}>
    //         <div className="row pb40">
    //           <PostJob />
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="dashboard_content_wrapper" style={{ marginTop: "-35px" }}>
      <div className="dashboard dashboard_wrapper pr30 pr0-xl">
        <SideMenu />
        <div
          className={isDesktopOrLaptop ? "dashboard__main" : ""}
          style={{ marginTop: isDesktopOrLaptop ? "50px" : "34px" }}
        >
          <div
            className="dashboard__content hover-bgc-color"
            style={{
              padding: ` ${isDesktopOrLaptop ? "26px" : "0px"} 15px 20px`,
              backgroundColor: "#fafafa",
            }}
          >
            <div className="row pb40">
              <div className="col-lg-12 pb10">
                <div className="dashboard_title_area">
                  <h5>Post A Job</h5>
                  <p className="text"></p>
                </div>
              </div>
              <PostJob />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostJobPage;
