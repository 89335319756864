import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import InfoModal from "../../../../components/model/InfoModel";
import BasicDetailsTab from "./Tabs/BasicDetailsTab";
import ChangePassword from "../ChangePassword/ChangePassword";
import { getToken } from "../../../../common/getToken";
import ProfileRegistration from "../ProfileRegistration/ProfileRegistration";
import { myDetail } from "../../../../API/UserApi";
import { Status } from "../../../../constants/constant";

const MyProfile = () => {
  const [showModal, setShowModal] = useState(false);
  const [messageType, setMessageType] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [status, setStatus] = useState("");
  const [showProfileRegistrationModal, setShowProfileRegistrationModal] =
    useState(false);
  const token = getToken();
  // const userInfo = getUserInfo();

  const myDetails = async () => {
    setStatus(Status.PENDING);
    try {
      const data = await myDetail();
      setUserInfo(data);
      localStorage.setItem("currentUser", JSON.stringify(data));
      // setSelectedOptions(formData?.skills || []);
      setStatus(Status.IDLE);
    } catch (error) {
      console.error("Error while getting user details", error);
      setStatus(Status.REJECTED);
    }
  };

  // useEffect(() => {
  //   myDetails();
  // }, []);

  useEffect(() => {
    myDetails();
    if (!userInfo) {
      return; // Exit early if userInfo is null or undefined
    }

    document.body.classList.add("modal-open");

    switch (userInfo?.profileStatus) {
      case "pending for review":
        setShowModal(true);
        setMessageType("pending");
        break;
      case "completed":
        if (userInfo?.approvePopup) {
          setShowModal(true);
          setMessageType("completed");
        }
        break;
      case "rejected":
        setShowModal(true);
        setMessageType("rejected");
        break;
      default:
        break;
    }
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [userInfo?.profileStatus]);

  const location = useLocation();
  return (
    <>
      {!token && <Navigate to="/" replace={true} />}
      <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
        {location.pathname === "/profile" && <BasicDetailsTab />}
        {location.pathname === "/change-password" && <ChangePassword />}
      </div>
      {showProfileRegistrationModal && (
        <ProfileRegistration
          showModal={showProfileRegistrationModal}
          setShowModal={setShowProfileRegistrationModal}
        />
      )}
      <InfoModal
        showModal={showModal}
        setShowModal={setShowModal}
        messageType={messageType}
        setShowProfileRegistrationModal={setShowProfileRegistrationModal}
      />
    </>
  );
};

export default MyProfile;
