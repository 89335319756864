import * as _ from "lodash";

import { axiosWithToken } from "../common/axiosConfig";
import {
  UserDetailsURL,
  UpdateUserURL,
  UpdateUserEmailStartURL,
  UpdateUserEmailVerifyURL,
  UpdateUserMobileStartURL,
  UpdateUserMobileVerifyURL,
  AddPortfolioURL,
  GetUserPortfoliosURL,
  NotificationListURL,
  UnseenNotificationListURL,
  ResendOtpURL,
  InvitationListURL,
  DeleteUserCategoryURL,
  ResendMobileOtpURL,
  UpdatePasswordURL,
} from "../constants/apiConstants";

export async function myDetail() {
  return axiosWithToken.get(UserDetailsURL).then((res) => {
    return _.get(res, "data.user", {});
  });
}

export async function updateUser(formValue) {
  return axiosWithToken.put(UpdateUserURL, formValue).then((res) => {
    return _.get(res, "data.message", {});
  });
}

export async function updateUserEmailStart(formValue) {
  return axiosWithToken.post(UpdateUserEmailStartURL, formValue).then((res) => {
    return _.get(res, "data.message", {});
  });
}

export async function updateUserEmailVerify(formValue) {
  return axiosWithToken
    .post(UpdateUserEmailVerifyURL, formValue)
    .then((res) => {
      return _.get(res, "data.message", {});
    });
}

export async function updateUserMobileStart(formValue) {
  return axiosWithToken
    .post(UpdateUserMobileStartURL, formValue)
    .then((res) => {
      return _.get(res, "data.message", {});
    });
}

export async function updateUserMobileVerify(formValue) {
  return axiosWithToken
    .post(UpdateUserMobileVerifyURL, formValue)
    .then((res) => {
      return _.get(res, "data.message", {});
    });
}

export async function addPortfolio(formValue) {
  return axiosWithToken.put(AddPortfolioURL, formValue).then((res) => {
    return _.get(res, "data.message", {});
  });
}

export async function getPortfolios() {
  return axiosWithToken.post(GetUserPortfoliosURL, {}).then((res) => {
    return _.get(res, "data.data", {});
  });
}

export async function getNotificationList() {
  return axiosWithToken.post(NotificationListURL, {}).then((res) => {
    return _.get(res, "data.data", {});
  });
}

export async function getUnseenNotificationList(value) {
  return axiosWithToken.post(UnseenNotificationListURL, value).then((res) => {
    return _.get(res, "data.data", {});
  });
}

export async function resendOtp(value) {
  return axiosWithToken.post(ResendOtpURL, value).then((res) => {
    return _.get(res, "data", {});
  });
}

export async function jobInvitations() {
  return axiosWithToken.post(InvitationListURL).then((res) => {
    return _.get(res, "data.data", {});
  });
}

export async function deleteCategory(value) {
  return axiosWithToken.post(DeleteUserCategoryURL, value).then((res) => {
    return _.get(res, "data", {});
  });
}

export async function resendMobileOtp(value) {
  return axiosWithToken.post(ResendMobileOtpURL, value).then((res) => {
    return _.get(res, "data", {});
  });
}

export async function updatePassword(value) {
  return axiosWithToken.put(UpdatePasswordURL, value).then((res) => {
    return _.get(res, "data.message", {});
  });
}
