function MessagesIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 216 216"
      width="30"
      height="30"
      role="img"
      style={{ width: "40px", height: "40px", marginTop: "1px" }}
    >
      <defs>
        <style>{`.st0 { fill: #fff; }`}</style>
      </defs>
      <path d="M60.32,159.29c-.88-1.1-4.29-3.25-5.62-4.5C4.49,107.58,41.25,33.17,106.45,30.51c49.08-2,93.11,41.03,79.03,91.25-11.41,40.68-57.61,61.89-97.28,51.24-7.67,3.65-14.96,8.9-22.55,12.59-2.67,1.3-4.4,2.12-4.84-1.78-.78-7.02.63-15.41.02-22.54-.06-.71-.03-1.38-.51-1.97ZM153.19,88.96c-.08-.13-1.09-.36-1.38-.13-8.72,4.36-17,9.65-25.69,14.04-2.49,1.26-4.55,3.16-7.08.95l-16.77-17.97-2.62-.28-35.19,37.35c-.44.73-.28,1.89.74,1.9,9.01-4.34,17.58-9.9,26.55-14.33,2.51-1.24,4.3-3.08,6.89-1.14,5.65,4.24,11.11,12.58,16.6,17.38,2.66,2.31,5.27-1.37,7.07-3.23,8.63-8.91,16.93-18.23,25.38-27.32,1.16-1.25,4.54-4.03,5.18-5.13.31-.54.63-1.6.33-2.09Z" />
      <path
        className="st0"
        d="M153.19,88.96c.3.49-.02,1.55-.33,2.09-.63,1.1-4.02,3.88-5.18,5.13-8.45,9.09-16.74,18.41-25.38,27.32-1.81,1.87-4.41,5.54-7.07,3.23-5.5-4.8-10.95-13.14-16.6-17.38-2.58-1.94-4.38-.09-6.89,1.14-8.97,4.43-17.54,9.99-26.55,14.33-1.02-.01-1.17-1.16-.74-1.9l35.19-37.35,2.62.28,16.77,17.97c2.53,2.21,4.59.31,7.08-.95,8.69-4.39,16.97-9.69,25.69-14.04.28-.23,1.3,0,1.38.13Z"
      />
    </svg>
  );
}

export default MessagesIcon;
