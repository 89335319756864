function BackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      aria-hidden="true"
      viewBox="0 0 24 24"
      role="img"
      style={{ width: "11%" }}
    >
      <path
        vector-effect="non-scaling-stroke"
        stroke="var(--icon-color, #001e00)"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.5"
        d="M14 18l-5-6 5-6"
      ></path>
    </svg>
  );
}

export default BackIcon;
