import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { myDetail, updateUser } from "../../API/UserApi";
import { ErrorToaster } from "../../shared/Toasters/toaster";
import { Status } from "../../constants/constant";
import { getUserInfo } from "../../common/getUserInfo";
import { Link } from "react-router-dom";

const InfoModal = ({
  showModal,
  setShowModal,
  messageType,
  setShowProfileRegistrationModal,
}) => {
  const [status, setStatus] = useState("");
  const userInfo = getUserInfo();

  const myDetails = async () => {
    setStatus(Status.PENDING);
    try {
      const data = await myDetail();
      localStorage.setItem("currentUser", JSON.stringify(data));
      setStatus(Status.IDLE);
    } catch (error) {
      console.error("Error while getting user details", error);
      setStatus(Status.REJECTED);
      // ErrorToaster('Something went wrong.');;;
    }
  };

  const updateUserDetails = async (data) => {
    setStatus(Status.PENDING);
    try {
      await updateUser({ approvePopup: false });
      setStatus(Status.IDLE);
      await myDetails();
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleOk = async () => {
    if (userInfo?.profileStatus === "completed") {
      await updateUserDetails();
      setShowModal(false);
    } else if (userInfo?.profileStatus === "rejected") {
      setShowModal(false);
      setShowProfileRegistrationModal(true);
    } else {
      setShowModal(false);
    }
  };

  return (
    <Modal size="lg" show={showModal} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="dark-color">Profile Status</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="d-flex align-items-center justify-content-center"
        style={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <p
          className="text-center dark-color"
          style={{ fontSize: "17px", wordWrap: "break-word" }}
        >
          {messageType === "pending" &&
            `Thank you for submitting your profile on Topshot. We are currently
        reviewing your profile, and you will receive an update on your
        registered email account.`}
          {messageType === "completed" && (
            <>
              {`Congratulations! Your profile has been approved, and we're thrilled to welcome you to our platform. Best of luck on your journey with us! Feel free to start applying for jobs right away. Additionally, check out our `}
              <Link to="/subscriptions">subscription plans</Link>
              {` to upgrade your profile and enjoy numerous perks.`}
            </>
          )}
          {messageType === "rejected" &&
            `We regret to inform you that your profile has been rejected. We encourage you to review the feedback sent to your registered email account, make the necessary adjustments to your profile, and resubmit it for consideration.`}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOk} className="ud-btn btn-thm accept">
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InfoModal;
