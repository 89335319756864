import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="body_content">
      <section className="our-terms">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="main-title">
                <h2>Privacy And Policy</h2>
                <p className="text">
                  Welcome to Topshot Photographer! This Privacy Policy explains
                  how we collect, use, and disclose personal information from
                  users of our platform. By accessing or using our platform, you
                  agree to the terms outlined in this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9 col-lg-9 col-xl-9 offset-xl-1">
              <div className="terms_condition_grid text-start">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-accountpayment"
                    role="tabpanel"
                    aria-labelledby="nav-accountpayment-tab"
                  >
                    <p className="mb25 text fz15">
                      This Privacy Policy describes Our policies and procedures
                      on the collection, use and disclosure of Your information
                      when You use the Service and tells You about Your privacy
                      rights and how the law protects You.
                    </p>
                    <p className="text fz15">
                      We use Your Personal data to provide and improve the
                      Service. By using the Service, You agree to the collection
                      and use of information in accordance with this Privacy
                      Policy.
                    </p>
                    <div className="grids mb40 mb40-md">
                      <h6 className="mt5 mb5">
                        {" "}
                        Interpretation and Definitions{" "}
                      </h6>
                      <h6 className="mt5 mb5">Interpretation</h6>
                      <p className="text fz15">
                        The words of which the initial letter is capitalized
                        have meanings defined under the following conditions.
                        The following definitions shall have the same meaning
                        regardless of whether they appear in singular or in
                        plural.
                      </p>
                      <h6 className="mt5 mb5">Definitions</h6>
                      <p className="text fz15">
                        For the purposes of this Privacy Policy:
                        <ul
                          style={{ listStyleType: "disc", marginLeft: "20px" }}
                        >
                          <li>
                            <strong className="mt5 mb5">Account</strong> means a
                            unique account created for You to access our Service
                            or parts of our Service.
                          </li>
                          <li>
                            <strong className="mt5 mb5">Affiliate</strong> means
                            an entity that controls, is controlled by, or is
                            under common control with a party, where "control"
                            means ownership of 50% or more of the shares, equity
                            interest, or other securities entitled to vote for
                            election of directors or other managing authority.
                          </li>
                          <li>
                            <strong className="mt5 mb5">Business</strong>, for
                            the purpose of CCPA/CPRA, refers to the Company as
                            the legal entity that collects Consumers' personal
                            information and determines the purposes and means of
                            the processing of Consumers' personal information,
                            or on behalf of which such information is collected
                            and that alone, or jointly with others, determines
                            the purposes and means of the processing of
                            Consumers' personal information, that does business
                            in the State of California.
                          </li>
                          <li>
                            <strong className="mt5 mb5">CCPA</strong> and/or{" "}
                            <strong className="mt5 mb5">CPRA</strong> refers to
                            the California Consumer Privacy Act (the "CCPA") as
                            amended by the California Privacy Rights Act of 2020
                            (the "CPRA").
                          </li>
                          <li>
                            <strong className="mt5 mb5">Company</strong>{" "}
                            (referred to as either "the Company", "We", "Us" or
                            "Our" in this Agreement) refers to Top Shot
                            Photographer LLC. For the purpose of the GDPR, the
                            Company is the Data Controller.
                          </li>
                          <li>
                            <strong className="mt5 mb5">Consumer</strong>, for
                            the purpose of the CCPA/CPRA, means a natural person
                            who is a California resident. A resident, as defined
                            in the law, includes (1) every individual who is in
                            the USA for other than a temporary or transitory
                            purpose, and (2) every individual who is domiciled
                            in the USA who is outside the USA for a temporary or
                            transitory purpose.
                          </li>
                          <li>
                            <strong className="mt5 mb5">Cookies</strong> are
                            small files that are placed on Your computer, mobile
                            device, or any other device by a website, containing
                            the details of Your browsing history on that website
                            among its many uses.
                          </li>
                          <li>
                            <strong className="mt5 mb5">Country</strong> refers
                            to: New York, United States.
                          </li>
                          <li>
                            <strong className="mt5 mb5">Data Controller</strong>
                            , for the purposes of the GDPR (General Data
                            Protection Regulation), refers to the Company as the
                            legal person which alone or jointly with others
                            determines the purposes and means of the processing
                            of Personal Data.
                          </li>
                          <li>
                            <strong className="mt5 mb5">Device</strong> means
                            any device that can access the Service such as a
                            computer, a cellphone, or a digital tablet.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Do Not Track (DNT)
                            </strong>{" "}
                            is a concept that has been promoted by US regulatory
                            authorities, in particular the U.S. Federal Trade
                            Commission (FTC), for the Internet industry to
                            develop and implement a mechanism for allowing
                            internet users to control the tracking of their
                            online activities across websites.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Facebook Fan Page
                            </strong>{" "}
                            is a public profile named Top Shot Photographer
                            specifically created by the Company on the Facebook
                            social network, accessible from{" "}
                            <a href="https://www.facebook.com/topshotphotographers">
                              https://www.facebook.com/topshotphotographers
                            </a>
                            .
                          </li>
                          <li>
                            <strong className="mt5 mb5">GDPR</strong> refers to
                            EU General Data Protection Regulation.
                          </li>
                          <li>
                            <strong className="mt5 mb5">Personal Data</strong>{" "}
                            is any information that relates to an identified or
                            identifiable individual.
                          </li>
                          <li>
                            <p className="text fz15">
                              {" "}
                              For the purposes of GDPR, Personal Data means any
                              information relating to You such as a name, an
                              identification number, location data, online
                              identifier or to one or more factors specific to
                              the physical, physiological, genetic, mental,
                              economic, cultural or social identity.
                            </p>
                            <p className="text fz15">
                              {" "}
                              For the purposes of the CCPA/CPRA, Personal Data
                              means any information that identifies, relates to,
                              describes or is capable of being associated with,
                              or could reasonably be linked, directly or
                              indirectly, with You.
                            </p>
                          </li>
                          <li>
                            <strong className="mt5 mb5">Service</strong> refers
                            to the Website.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Service Provider
                            </strong>{" "}
                            means any natural or legal person who processes the
                            data on behalf of the Company. It refers to
                            third-party companies or individuals employed by the
                            Company to facilitate the Service, to provide the
                            Service on behalf of the Company, to perform
                            services related to the Service or to assist the
                            Company in analyzing how the Service is used. For
                            the purpose of the GDPR, Service Providers are
                            considered Data Processors.
                          </li>
                          <li>
                            <strong className="mt5 mb5">Usage Data</strong>{" "}
                            refers to data collected automatically, either
                            generated by the use of the Service or from the
                            Service infrastructure itself (for example, the
                            duration of a page visit).
                          </li>
                          <li>
                            <strong className="mt5 mb5">Website</strong> refers
                            to Top Shot Photographer, accessible from{" "}
                            <a href="http://www.topshotphotographer.com">
                              www.topshotphotographer.com
                            </a>
                            .
                          </li>
                          <li>
                            <strong className="mt5 mb5">You</strong> means the
                            individual accessing or using the Service, or the
                            company, or other legal entity on behalf of which
                            such individual is accessing or using the Service,
                            as applicable.
                            <p className="text fz15">
                              Under GDPR, You can be referred to as the Data
                              Subject or as the User as you are the individual
                              using the Service.
                            </p>
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">
                        Collecting and Using Your Personal Data
                      </h6>
                      <h6 className="mt5 mb5">Types of Data Collected</h6>
                      <h6 className="mt5 mb5">Personal Data</h6>
                      <p className="text fz15">
                        While using Our Service, We may ask You to provide Us
                        with certain personally identifiable information that
                        can be used to contact or identify You. Personally
                        identifiable information may include, but is not limited
                        to:
                        <ul
                          style={{ listStyleType: "disc", marginLeft: "20px" }}
                        >
                          <li>Email address</li>
                          <li>First name and last name</li>
                          <li>Phone number</li>
                          <li>
                            Address, State, Province, ZIP/Postal code, City
                          </li>
                          <li>Usage Data</li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">Usage Data</h6>
                      <p className="text fz15">
                        Usage Data is collected automatically when using the
                        Service.{" "}
                      </p>
                      <p className="text fz15">
                        Usage Data may include information such as Your Device's
                        Internet Protocol address (e.g. IP address), browser
                        type, browser version, the pages of our Service that You
                        visit, the time and date of Your visit, the time spent
                        on those pages, unique device identifiers and other
                        diagnostic data.
                      </p>
                      <p className="text fz15">
                        When You access the Service by or through a mobile
                        device, We may collect certain information
                        automatically, including, but not limited to, the type
                        of mobile device You use, Your mobile device unique ID,
                        the IP address of Your mobile device, Your mobile
                        operating system, the type of mobile Internet browser
                        You use, unique device identifiers and other diagnostic
                        data.
                      </p>
                      <p className="text fz15">
                        We may also collect information that Your browser sends
                        whenever You visit our Service or when You access the
                        Service by or through a mobile device.
                      </p>
                      .
                      <h6 className="mt5 mb5">
                        Tracking Technologies and Cookies
                      </h6>
                      <p className="text fz15">
                        We use Cookies and similar tracking technologies to
                        track the activity on Our Service and store certain
                        information. Tracking technologies used are beacons,
                        tags, and scripts to collect and track information and
                        to improve and analyze Our Service. The technologies We
                        use may include:
                        <ul
                          style={{ listStyleType: "disc", marginLeft: "20px" }}
                        >
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            <strong className="mt5 mb5">
                              Cookies or Browser Cookies.{" "}
                            </strong>{" "}
                            A cookie is a small file placed on Your Device. You
                            can instruct Your browser to refuse all Cookies or
                            to indicate when a Cookie is being sent. However, if
                            You do not accept Cookies, You may not be able to
                            use some parts of our Service. Unless you have
                            adjusted Your browser setting so that it will refuse
                            Cookies, our Service may use Cookies.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>{" "}
                            <strong className="mt5 mb5"> Web Beacons. </strong>
                            Certain sections of our Service and our emails may
                            contain small electronic files known as web beacons
                            (also referred to as clear gifs, pixel tags, and
                            single-pixel gifs) that permit the Company, for
                            example, to count users who have visited those pages
                            or opened an email and for other related website
                            statistics (for example, recording the popularity of
                            a certain section and verifying system and server
                            integrity).
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        Cookies can be "Persistent" or "Session" Cookies.
                        Persistent Cookies remain on Your personal computer or
                        mobile device when You go offline, while Session Cookies
                        are deleted as soon as You close Your web browser. You
                        can learn more about cookies on TermsFeed website
                        article.
                      </p>
                      <p className="text fz15">
                        {" "}
                        We use both Session and Persistent Cookies for the
                        purposes set out below:
                        <ul
                          style={{ listStyleType: "disc", marginLeft: "20px" }}
                        >
                          <li>
                            <strong className="mt5 mb5">
                              Necessary / Essential Cookies
                            </strong>
                          </li>
                          <li> Type: Session Cookies</li>
                          <li> Administered by: Us</li>
                          <li>
                            Purpose: These Cookies are essential to provide You
                            with services available through the Website and to
                            enable You to use some of its features. They help to
                            authenticate users and prevent fraudulent use of
                            user accounts. Without these Cookies, the services
                            that You have asked for cannot be provided, and We
                            only use these Cookies to provide You with those
                            services.
                          </li>
                          <li>
                            {" "}
                            <strong className="mt5 mb5">
                              Cookies Policy / Notice Acceptance Cookies
                            </strong>{" "}
                          </li>
                          <li>Type: Persistent Cookies</li>
                          <li>Administered by: Us</li>
                          <li>
                            Purpose: These Cookies identify if users have
                            accepted the use of cookies on the Website.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Functionality Cookies
                            </strong>
                          </li>
                          <li>Type: Persistent Cookies</li>
                          <li>Administered by: Us</li>
                          <li>
                            Purpose: These Cookies allow us to remember choices
                            You make when You use the Website, such as
                            remembering your login details or language
                            preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to
                            avoid You having to re-enter your preferences every
                            time You use the Website.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Tracking and Performance Cookies
                            </strong>
                          </li>
                          <li>Type: Persistent Cookies</li>
                          <li>Administered by: Third-Parties</li>
                          <li>
                            Purpose: These Cookies are used to track information
                            about traffic to the Website and how users use the
                            Website. The information gathered via these Cookies
                            may directly or indirectly identify you as an
                            individual visitor. This is because the information
                            collected is typically linked to a pseudonymous
                            identifier associated with the device you use to
                            access the Website. We may also use these Cookies to
                            test new pages, features or new functionality of the
                            Website to see how our users react to them.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Cookies Policy / Notice Acceptance Cookies
                            </strong>
                          </li>
                          <li>Type: Persistent Cookies</li>
                          <li>Administered by: Us</li>
                          <li>
                            Purpose: These Cookies identify if users have
                            accepted the use of cookies on the Website.
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        For more information about the cookies we use and your
                        choices regarding cookies, please visit our Cookies
                        Policy or the Cookies section of our Privacy Policy.
                      </p>
                      <h6 className="mt5 mb5">Use of Your Personal Data</h6>
                      <p className="text fz15">
                        The Company may use Personal Data for the following
                        purposes:
                        <ul
                          style={{ listStyleType: "disc", marginLeft: "20px" }}
                        >
                          <li>
                            <strong className="mt5 mb5">
                              To provide and maintain our Service,
                            </strong>{" "}
                            including to monitor the usage of our Service.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              To provide and maintain our Service,
                            </strong>
                            including to monitor the usage of our Service.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              To manage Your Account:
                            </strong>{" "}
                            to manage Your registration as a user of the
                            Service. The Personal Data You provide can give You
                            access to different functionalities of the Service
                            that are available to You as a registered user.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              For the performance of a contract:
                            </strong>{" "}
                            the development, compliance and undertaking of the
                            purchase contract for the products, items or
                            services You have purchased or of any other contract
                            with Us through the Service.
                          </li>
                          <li>
                            <strong className="mt5 mb5">To contact You:</strong>{" "}
                            To contact You by email, telephone calls, SMS, or
                            other equivalent forms of electronic communication,
                            such as a mobile application's push notifications
                            regarding updates or informative communications
                            related to the functionalities, products or
                            contracted services, including the security updates,
                            when necessary or reasonable for their
                            implementation.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              To provide You with news, special offers and
                              general information
                            </strong>{" "}
                            about other goods, services and events which we
                            offer that are similar to those that you have
                            already purchased or enquired about unless You have
                            opted not to receive such information.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              To manage Your requests:
                            </strong>{" "}
                            To attend and manage Your requests to Us.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              For business transfers:
                            </strong>{" "}
                            We may use Your information to evaluate or conduct a
                            merger, divestiture, restructuring, reorganization,
                            dissolution, or other sale or transfer of some or
                            all of Our assets, whether as a going concern or as
                            part of bankruptcy, liquidation, or similar
                            proceeding, in which Personal Data held by Us about
                            our Service users is among the assets transferred.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              For other purposes:
                            </strong>{" "}
                            We may use Your information for other purposes, such
                            as data analysis, identifying usage trends,
                            determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Service,
                            products, services, marketing and your experience.
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        We may share Your personal information in the following
                        situations:
                        <ul
                          style={{
                            listStyleType: "disc",
                            marginLeft: "20px",
                          }}
                        >
                          <li>
                            <strong className="mt5 mb5">
                              With Service Providers:
                            </strong>{" "}
                            We may share Your personal information with Service
                            Providers to monitor and analyze the use of our
                            Service, for payment processing, to contact You.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              For business transfers:
                            </strong>{" "}
                            We may share or transfer Your personal information
                            in connection with, or during negotiations of, any
                            merger, sale of Company assets, financing, or
                            acquisition of all or a portion of Our business to
                            another company.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              With Affiliates:
                            </strong>{" "}
                            We may share Your information with Our affiliates,
                            in which case we will require those affiliates to
                            honor this Privacy Policy. Affiliates include Our
                            parent company and any other subsidiaries, joint
                            venture partners or other companies that We control
                            or that are under common control with Us.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              With business partners:
                            </strong>{" "}
                            We may share Your information with Our business
                            partners to offer You certain products, services or
                            promotions.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              With other users:
                            </strong>{" "}
                            when You share personal information or otherwise
                            interact in the public areas with other users, such
                            information may be viewed by all users and may be
                            publicly distributed outside.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              With Your consent:
                            </strong>{" "}
                            We may disclose Your personal information for any
                            other purpose with Your consent.
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">
                        {" "}
                        Retention of Your Personal Data{" "}
                      </h6>
                      <p className="text fz15">
                        The Company will retain Your Personal Data only for as
                        long as is necessary for the purposes set out in this
                        Privacy Policy. We will retain and use Your Personal
                        Data to the extent necessary to comply with our legal
                        obligations (for example, if we are required to retain
                        your data to comply with applicable laws), resolve
                        disputes, and enforce our legal agreements and policies.
                      </p>
                      <p className="text fz15">
                        The Company will also retain Usage Data for internal
                        analysis purposes. Usage Data is generally retained for
                        a shorter period of time, except when this data is used
                        to strengthen the security or to improve the
                        functionality of Our Service, or We are legally
                        obligated to retain this data for longer time periods.
                      </p>
                      <h6 className="mt5 mb5">
                        Transfer of Your Personal Data
                      </h6>
                      <p className="text fz15">
                        Your information, including Personal Data, is processed
                        at the Company's operating offices and in any other
                        places where the parties involved in the processing are
                        located. It means that this information may be
                        transferred to — and maintained on — computers located
                        outside of Your state, province, country or other
                        governmental jurisdiction where the data protection laws
                        may differ than those from Your jurisdiction.
                      </p>
                      <p className="text fz15">
                        Your consent to this Privacy Policy followed by Your
                        submission of such information represents Your agreement
                        to that transfer.
                      </p>
                      <p className="text fz15">
                        The Company will take all steps reasonably necessary to
                        ensure that Your data is treated securely and in
                        accordance with this Privacy Policy and no transfer of
                        Your Personal Data will take place to an organization or
                        a country unless there are adequate controls in place
                        including the security of Your data and other personal
                        information.
                      </p>
                      <h6 className="mt5 mb5">Delete Your Personal Data</h6>
                      <p className="text fz15">
                        You have the right to delete or request that We assist
                        in deleting the Personal Data that We have collected
                        about You.
                      </p>
                      <p className="text fz15">
                        Our Service may give You the ability to delete certain
                        information about You from within the Service.
                      </p>
                      <p className="text fz15">
                        You may update, amend, or delete Your information at any
                        time by signing in to Your Account, if you have one, and
                        visiting the account settings section that allows you to
                        manage Your personal information. You may also contact
                        Us to request access to, correct, or delete any personal
                        information that You have provided to Us.
                      </p>
                      <p className="text fz15">
                        Please note, however, that We may need to retain certain
                        information when we have a legal obligation or lawful
                        basis to do so.
                      </p>
                      <h6 className="mt5 mb5">
                        Disclosure of Your Personal Data
                      </h6>
                      <h6 className="mt5 mb5">Business Transactions</h6>
                      <p className="text fz15">
                        If the Company is involved in a merger, acquisition or
                        asset sale, Your Personal Data may be transferred. We
                        will provide notice before Your Personal Data is
                        transferred and becomes subject to a different Privacy
                        Policy. Law enforcement Under certain circumstances, the
                        Company may be required to disclose Your Personal Data
                        if required to do so by law or in response to valid
                        requests by public authorities (e.g. a court or a
                        government agency).
                      </p>
                      <h6 className="mt5 mb5">Law enforcement</h6>
                      <p className="text fz15">
                        Under certain circumstances, the Company may be required
                        to disclose Your Personal Data if required to do so by
                        law or in response to valid requests by public
                        authorities (e.g. a court or a government agency).
                      </p>
                      <h6 className="mt5 mb5">Other legal requirements</h6>
                      <p className="text fz15">
                        The Company may disclose Your Personal Data in the good
                        faith belief that such action is necessary to:
                        <ul>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>Comply
                            with a legal obligation
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Protect and defend the rights or property of the
                            Company
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Prevent or investigate possible wrongdoing in
                            connection with the Service
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Protect the personal safety of Users of the Service
                            or the public
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>Protect
                            against legal liability
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">
                        Security of Your Personal Data
                      </h6>
                      <p className="text fz15">
                        The security of Your Personal Data is important to Us,
                        but remember that no method of transmission over the
                        Internet, or method of electronic storage is 100%
                        secure. While We strive to use commercially acceptable
                        means to protect Your Personal Data, We cannot guarantee
                        its absolute security.
                      </p>
                      <h6 className="mt5 mb5">
                        Detailed Information on the Processing of Your Personal
                        Data
                      </h6>
                      <p className="text fz15">
                        The Service Providers We use may have access to Your
                        Personal Data. These third-party vendors collect, store,
                        use, process and transfer information about Your
                        activity on Our Service in accordance with their Privacy
                        Policies.
                      </p>
                      <h6 className="mt5 mb5">Analytics</h6>
                      <p className="text fz15">
                        We may use third-party Service providers to monitor and
                        analyze the use of our Service.
                        <ul>
                          <li>
                            <strong className="mt5 mb5">
                              Google Analytics
                            </strong>
                          </li>
                          <li>
                            Google Analytics is a web analytics service offered
                            by Google that tracks and reports website traffic.
                            Google uses the data collected to track and monitor
                            the use of our Service. This data is shared with
                            other Google services. Google may use the collected
                            data to contextualize and personalize the ads of its
                            own advertising network.
                          </li>
                          <li>
                            You can opt-out of having made your activity on the
                            Service available to Google Analytics by installing
                            the Google Analytics opt-out browser add-on. The
                            add-on prevents the Google Analytics JavaScript
                            (ga.js, analytics.js and dc.js) from sharing
                            information with Google Analytics about visits
                            activity.
                          </li>
                          <li>
                            For more information on the privacy practices of
                            Google, please visit the Google Privacy & Terms web
                            page:{" "}
                            <a href="https://policies.google.com/privacy">
                              https://policies.google.com/privacy
                            </a>
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">Email Marketing</h6>
                      <p className="text fz15">
                        We may use Your Personal Data to contact You with
                        newsletters, marketing or promotional materials and
                        other information that may be of interest to You. You
                        may opt-out of receiving any, or all, of these
                        communications from Us by following the unsubscribe link
                        or instructions provided in any email We send or by
                        contacting Us.
                      </p>
                      <p className="text fz15">
                        We may use Email Marketing Service Providers to manage
                        and send emails to You.
                        <ul>
                          <li>
                            <strong className="mt5 mb5">Mailchimp</strong>
                          </li>
                          <li>
                            Mailchimp is an email marketing sending service
                            provided by The Rocket Science Group LLC.
                          </li>
                          <li>
                            For more information on the privacy practices of
                            Mailchimp, please visit their Privacy policy:
                            <a href="https://mailchimp.com/legal/privacy/">
                              https://mailchimp.com/legal/privacy/
                            </a>
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">Payments</h6>
                      <p className="text fz15">
                        We may provide paid products and/or services within the
                        Service. In that case, we may use third-party services
                        for payment processing (e.g. payment processors).
                      </p>
                      <p className="text fz15">
                        We will not store or collect Your payment card details.
                        That information is provided directly to Our third-party
                        payment processors whose use of Your personal
                        information is governed by their Privacy Policy. These
                        payment processors adhere to the standards set by
                        PCI-DSS as managed by the PCI Security Standards
                        Council, which is a joint effort of brands like Visa,
                        Mastercard, American Express and Discover. PCI-DSS
                        requirements help ensure the secure handling of payment
                        information.
                        <ul>
                          <li>
                            <strong className="mt5 mb5">Stripe</strong>
                          </li>
                          <li>
                            Their Privacy Policy can be viewed at
                            https://stripe.com/us/privacy
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">GDPR Privacy</h6>
                      <p className="text fz15">
                        <strong className="mt5 mb5">
                          Legal Basis for Processing Personal Data under GDPR
                        </strong>
                      </p>
                      <p className="text fz15">
                        We may process Personal Data under the following
                        conditions:
                        <ul>
                          <li>
                            <strong className="mt5 mb5">Consent:</strong> You
                            have given Your consent for processing Personal Data
                            for one or more specific purposes.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Performance of a contract:
                            </strong>{" "}
                            Provision of Personal Data is necessary for the
                            performance of an agreement with You and/or for any
                            pre-contractual obligations thereof.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Legal obligations:
                            </strong>{" "}
                            Processing Personal Data is necessary for compliance
                            with a legal obligation to which the Company is
                            subject.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Vital interests:
                            </strong>{" "}
                            Processing Personal Data is necessary in order to
                            protect Your vital interests or of another natural
                            person.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Public interests:
                            </strong>{" "}
                            Processing Personal Data is related to a task that
                            is carried out in the public interest or in the
                            exercise of official authority vested in the
                            Company.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Legitimate interests:
                            </strong>{" "}
                            Processing Personal Data is necessary for the
                            purposes of the legitimate interests pursued by the
                            Company.
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        In any case, the Company will gladly help to clarify the
                        specific legal basis that applies to the processing, and
                        in particular whether the provision of Personal Data is
                        a statutory or contractual requirement, or a requirement
                        necessary to enter into a contract.
                      </p>
                      <h6 className="mt5 mb5">Your Rights under the GDPR</h6>
                      <p className="text fz15">
                        The Company undertakes to respect the confidentiality of
                        Your Personal Data and to guarantee You can exercise
                        Your rights.
                      </p>
                      <p className="text fz15">
                        You have the right under this Privacy Policy, and by law
                        if You are within the EU, to:
                        <ul>
                          <li>
                            <strong className="mt5 mb5">
                              Request access to Your Personal Data:
                            </strong>{" "}
                            The right to access, update or delete the
                            information We have on You. Whenever made possible,
                            you can access, update or request deletion of Your
                            Personal Data directly within Your account settings
                            section. If you are unable to perform these actions
                            yourself, please contact Us to assist You. This also
                            enables You to receive a copy of the Personal Data
                            We hold about You.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Request correction:
                            </strong>{" "}
                            You have the right to have any incomplete or
                            inaccurate information We hold about You corrected.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Object to processing:
                            </strong>{" "}
                            This right exists where We are relying on a
                            legitimate interest as the legal basis for Our
                            processing and there is something about Your
                            particular situation, which makes You want to object
                            to our processing of Your Personal Data on this
                            ground. You also have the right to object where We
                            are processing Your Personal Data for direct
                            marketing purposes.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Request erasure:
                            </strong>{" "}
                            You have the right to ask Us to delete or remove
                            Personal Data when there is no good reason for Us to
                            continue processing it.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Request transfer:
                            </strong>{" "}
                            We will provide to You, or to a third-party You have
                            chosen, Your Personal Data in a structured, commonly
                            used, machine-readable format. Please note that this
                            right only applies to automated information which
                            You initially provided consent for Us to use or
                            where We used the information to perform a contract
                            with You.
                          </li>
                          <li>
                            <strong className="mt5 mb5">
                              Withdraw consent:
                            </strong>{" "}
                            You have the right to withdraw Your consent on using
                            your Personal Data. If You withdraw Your consent, We
                            may not be able to provide You with access to
                            certain specific functionalities of the Service.
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">
                        Exercising of Your GDPR Data Protection Rights
                      </h6>
                      <p className="text fz15">
                        You may exercise Your rights of access, rectification,
                        cancellation and opposition by contacting Us. Please
                        note that we may ask You to verify Your identity before
                        responding to such requests. If You make a request, We
                        will try our best to respond to You as soon as possible.
                      </p>
                      <p className="text fz15">
                        You have the right to complain to a Data Protection
                        Authority about Our collection and use of Your Personal
                        Data. For more information, if You are in the European
                        Economic Area (EEA), please contact Your local data
                        protection authority in the EEA.
                      </p>
                      <h6 className="mt5 mb5">Facebook Fan Page</h6>
                      <h6 className="mt5 mb5">
                        Data Controller for the Facebook Fan Page
                      </h6>
                      <p className="text fz15">
                        The Company is the Data Controller of Your Personal Data
                        collected while using the Service. As operator of the
                        Facebook Fan Page{" "}
                        <a href="https://www.facebook.com/topshotphotographers">
                          https://www.facebook.com/topshotphotographers
                        </a>
                        , the Company and the operator of the social network
                        Facebook are Joint Controllers.
                      </p>
                      <p className="text fz15">
                        The Company has entered into agreements with Facebook
                        that define the terms for use of the Facebook Fan Page,
                        among other things. These terms are mostly based on the
                        Facebook Terms of Service:{" "}
                        <a href="https://www.facebook.com/terms.php">
                          https://www.facebook.com/terms.php
                        </a>
                      </p>
                      <p className="text fz15">
                        Visit the Facebook Privacy Policy{" "}
                        <a href="https://www.facebook.com/policy.php">
                          https://www.facebook.com/policy.php
                        </a>{" "}
                        for more information about how Facebook manages Personal
                        data or contact Facebook online, or by mail: Facebook,
                        Inc. ATTN, Privacy Operations, 1601 Willow Road, Menlo
                        Park, CA 94025, United States.
                      </p>
                      <h6 className="mt5 mb5">Facebook Insights</h6>
                      <p className="text fz15">
                        We use the Facebook Insights function in connection with
                        the operation of the Facebook Fan Page and on the basis
                        of the GDPR, in order to obtain anonymized statistical
                        data about Our users.
                      </p>
                      <p className="text fz15">
                        For this purpose, Facebook places a Cookie on the device
                        of the user visiting Our Facebook Fan Page. Each Cookie
                        contains a unique identifier code and remains active for
                        a period of two years, except when it is deleted before
                        the end of this period.
                      </p>
                      <p className="text fz15">
                        Facebook receives, records and processes the information
                        stored in the Cookie, especially when the user visits
                        the Facebook services, services that are provided by
                        other members of the Facebook Fan Page and services by
                        other companies that use Facebook services.
                      </p>
                      <p className="text fz15">
                        For more information on the privacy practices of
                        Facebook, please visit Facebook Privacy Policy here:{" "}
                        <a href="https://www.facebook.com/privacy/explanation">
                          https://www.facebook.com/privacy/explanation
                        </a>
                      </p>
                      <h6 className="mt5 mb5">
                        CCPA/CPRA Privacy Notice (California Privacy Rights)
                      </h6>
                      <p className="text fz15">
                        This privacy notice section for California residents
                        supplements the information contained in Our Privacy
                        Policy and it applies solely to all visitors, users, and
                        others who reside in the State of California.
                      </p>
                      <h6 className="mt5 mb5">
                        Categories of Personal Information Collected
                      </h6>
                      <p className="text fz15">
                        We collect information that identifies, relates to,
                        describes, references, is capable of being associated
                        with, or could reasonably be linked, directly or
                        indirectly, with a particular Consumer or Device. The
                        following is a list of categories of personal
                        information which we may collect or may have been
                        collected from California residents within the last
                        twelve (12) months.
                      </p>
                      <p className="text fz15">
                        Please note that the categories and examples provided in
                        the list below are those defined in the CCPA/CPRA. This
                        does not mean that all examples of that category of
                        personal information were in fact collected by Us, but
                        reflects our good faith belief to the best of Our
                        knowledge that some of that information from the
                        applicable category may be and may have been collected.
                        For example, certain categories of personal information
                        would only be collected if You provided such personal
                        information directly to Us.
                        <ul>
                          <li>
                            <strong className="mt5 mb5">
                              Category A: Identifiers.
                            </strong>
                          </li>
                          <li>
                            Examples: A real name, alias, postal address, unique
                            personal identifier, online identifier, Internet
                            Protocol address, email address, account name,
                            driver's license number, passport number, or other
                            similar identifiers.
                          </li>
                          <li>Collected: Yes.</li>
                          <li>
                            <strong className="mt5 mb5">
                              Category B: Personal information categories listed
                              in the California Customer Records statute (Cal.
                              Civ. Code § 1798.80(e)).
                            </strong>
                          </li>
                          <li>
                            Examples: A name, signature, Social Security number,
                            physical characteristics or description, address,
                            telephone number, passport number, driver's license
                            or state identification card number, insurance
                            policy number, education, employment, employment
                            history, bank account number, credit card number,
                            debit card number, or any other financial
                            information, medical information, or health
                            insurance information. Some personal information
                            included in this category may overlap with other
                            categories.
                          </li>
                          <li>Collected: Yes.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category C: Protected classification
                              characteristics under California or federal law.
                            </strong>
                          </li>
                          <li>
                            Examples: Age (40 years or older), race, color,
                            ancestry, national origin, citizenship, religion or
                            creed, marital status, medical condition, physical
                            or mental disability, sex (including gender, gender
                            identity, gender expression, pregnancy or childbirth
                            and related medical conditions), sexual orientation,
                            veteran or military status, genetic information
                            (including familial genetic information).
                          </li>
                          <li>Collected: No.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category D: Commercial information.
                            </strong>
                          </li>
                          <li>
                            Examples: Records and history of products or
                            services purchased or considered.
                          </li>
                          <li>Collected: Yes.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category E: Biometric information.
                            </strong>
                          </li>
                          <li>
                            Examples: Genetic, physiological, behavioral, and
                            biological characteristics, or activity patterns
                            used to extract a template or other identifier or
                            identifying information, such as, fingerprints,
                            faceprints, and voiceprints, iris or retina scans,
                            keystroke, gait, or other physical patterns, and
                            sleep, health, or exercise data.
                          </li>
                          <li>Collected: No.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category F: Internet or other similar network
                              activity.
                            </strong>
                          </li>
                          <li>
                            Examples: Interaction with our Service or
                            advertisement.
                          </li>
                          <li>Collected: Yes.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category G: Geolocation data.
                            </strong>
                          </li>
                          <li>Examples: Approximate physical location.</li>
                          <li>Collected: No.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category H: Sensory data.
                            </strong>
                          </li>
                          <li>
                            Examples: Audio, electronic, visual, thermal,
                            olfactory, or similar information.
                          </li>
                          <li>Collected: No.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category I: Professional or employment-related
                              information.
                            </strong>
                          </li>
                          <li>
                            Examples: Current or past job history or performance
                            evaluations.
                          </li>
                          <li>Collected: No.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category J: Non-public education information (per
                              the Family Educational Rights and Privacy Act (20
                              U.S.C. Section 1232g, 34 C.F.R. Part 99)).
                            </strong>
                          </li>
                          <li>
                            Examples: Education records directly related to a
                            student maintained by an educational institution or
                            party acting on its behalf, such as grades,
                            transcripts, class lists, student schedules, student
                            identification codes, student financial information,
                            or student disciplinary records.
                          </li>
                          <li>Collected: No.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category K: Inferences drawn from other personal
                              information.
                            </strong>
                          </li>
                          <li>
                            Examples: Profile reflecting a person's preferences,
                            characteristics, psychological trends,
                            predispositions, behavior, attitudes, intelligence,
                            abilities, and aptitudes.
                          </li>
                          <li>Collected: No.</li>

                          <li>
                            <strong className="mt5 mb5">
                              Category L: Sensitive personal information.
                            </strong>
                          </li>
                          <li>
                            Examples: Account login and password information,
                            geolocation data.
                          </li>
                          <li>Collected: Yes.</li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        Under CCPA/CPRA, personal information does not include:
                        <ul>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Publicly available information from government
                            records
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Deidentified or aggregated consumer information
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Information excluded from the CCPA/CPRA's scope,
                            such as:{" "}
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Health or medical information covered by the Health
                            Insurance Portability and Accountability Act of 1996
                            (HIPAA) and the California Confidentiality of
                            Medical Information Act (CMIA) or clinical trial
                            data
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Personal Information covered by certain
                            sector-specific privacy laws, including the Fair
                            Credit Reporting Act (FRCA), the Gramm-Leach-Bliley
                            Act (GLBA) or California Financial Information
                            Privacy Act (FIPA), and the Driver's Privacy
                            Protection Act of 1994
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">
                        Sources of Personal Information
                      </h6>
                      <p className="text fz15">
                        We obtain the categories of personal information listed
                        above from the following categories of sources:
                        <ul>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            <strong className="mt5 mb5">
                              Directly from You. For example,
                            </strong>
                            from the forms You complete on our Service,
                            preferences You express or provide through our
                            Service, or from Your purchases on our Service.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>{" "}
                            <strong className="mt5 mb5">
                              {" "}
                              Indirectly from You. For example,
                            </strong>{" "}
                            from observing Your activity on our Service.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>{" "}
                            <strong className="mt5 mb5">
                              {" "}
                              Automatically from You. For example,
                            </strong>{" "}
                            through cookies We or our Service Providers set on
                            Your Device as You navigate through our Service.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>{" "}
                            <strong className="mt5 mb5">
                              {" "}
                              From Service Providers. For example,
                            </strong>{" "}
                            third-party vendors to monitor and analyze the use
                            of our Service, third-party vendors for payment
                            processing, or other third-party vendors that We use
                            to provide the Service to You.
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">Use of Personal Information</h6>
                      <p className="text fz15">
                        We may use or disclose personal information We collect
                        for "business purposes" or "commercial purposes" (as
                        defined under the CCPA/CPRA), which may include the
                        following examples:
                        <ul>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            To operate our Service and provide You with Our
                            Service.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            To provide You with support and to respond to Your
                            inquiries, including to investigate and address Your
                            concerns and monitor and improve our Service.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            To fulfill or meet the reason You provided the
                            information. For example, if You share Your contact
                            information to ask a question about our Service, We
                            will use that personal information to respond to
                            Your inquiry. If You provide Your personal
                            information to purchase a product or service, We
                            will use that information to process Your payment
                            and facilitate delivery.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            To respond to law enforcement requests and as
                            required by applicable law, court order, or
                            governmental regulations.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            As described to You when collecting Your personal
                            information or as otherwise set forth in the
                            CCPA/CPRA.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            For internal administrative and auditing purposes.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            To detect security incidents and protect against
                            malicious, deceptive, fraudulent or illegal
                            activity, including, when necessary, to prosecute
                            those responsible for such activities.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Other one-time uses.
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        Please note that the examples provided above are
                        illustrative and not intended to be exhaustive. For more
                        details on how we use this information, please refer to
                        the "Use of Your Personal Data" section.
                      </p>
                      <p className="text fz15">
                        If We decide to collect additional categories of
                        personal information or use the personal information We
                        collected for materially different, unrelated, or
                        incompatible purposes, We will update this Privacy
                        Policy.
                      </p>
                      <h6 className="mt5 mb5">
                        Disclosure of Personal Information
                      </h6>
                      <p className="text fz15">
                        We may use or disclose and may have used or disclosed in
                        the last twelve (12) months the following categories of
                        personal information for business or commercial
                        purposes:
                        <ul>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Category A: Identifiers
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Category B: Personal information categories listed
                            in the California Customer Records statute (Cal.
                            Civ. Code § 1798.80(e))
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Category D: Commercial information
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Category F: Internet or other similar network
                            activity
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        Please note that the categories listed above are those
                        defined in the CCPA/CPRA. This does not mean that all
                        examples of that category of personal information were
                        in fact disclosed, but reflects our good faith belief to
                        the best of our knowledge that some of that information
                        from the applicable category may be and may have been
                        disclosed.
                      </p>
                      <p className="text fz15">
                        When We disclose personal information for a business
                        purpose or a commercial purpose, We enter a contract
                        that describes the purpose and requires the recipient to
                        both keep that personal information confidential and not
                        use it for any purpose except performing the contract.
                      </p>
                      <h6 className="mt5 mb5">Share of Personal Information</h6>
                      <p className="text fz15">
                        We may share, and have shared in the last twelve (12)
                        months, Your personal information identified in the
                        above categories with the following categories of third
                        parties:
                        <ul>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Service Providers
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Payment processors
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Our affiliates
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Our business partners
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Third-party vendors to whom You or Your agents
                            authorize Us to disclose Your personal information
                            in connection with products or services We provide
                            to You
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">Sale of Personal Information</h6>
                      <p className="text fz15">
                        As defined in the CCPA/CPRA, "sell" and "sale" mean
                        selling, renting, releasing, disclosing, disseminating,
                        making available, transferring, or otherwise
                        communicating orally, in writing, or by electronic or
                        other means, a Consumer's personal information by the
                        Business to a third party for valuable consideration.
                        This means that We may have received some kind of
                        benefit in return for sharing personal information, but
                        not necessarily a monetary benefit.
                      </p>
                      <p className="text fz15">
                        We do not sell personal information as the term sell is
                        commonly understood. We do allow Service Providers to
                        use Your personal information for the business purposes
                        described in Our Privacy Policy, for activities such as
                        advertising, marketing, and analytics, and these may be
                        deemed a sale under CCPA/CPRA.
                      </p>
                      <p className="text fz15">
                        We may sell and may have sold in the last twelve (12)
                        months the following categories of personal information:
                        <ul>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Category A: Identifiers
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Category B: Personal information categories listed
                            in the California Customer Records statute (Cal.
                            Civ. Code § 1798.80(e))
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Category D: Commercial information
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Category F: Internet or other similar network
                            activity
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        Please note that the categories listed above are those
                        defined in the CCPA/CPRA. This does not mean that all
                        examples of that category of personal information were
                        in fact sold, but reflects our good faith belief to the
                        best of Our knowledge that some of that information from
                        the applicable category may be and may have been shared
                        for value in return.
                      </p>
                      <h6 className="mt5 mb5">
                        Sale of Personal Information of Minors Under 16 Years of
                        Age
                      </h6>
                      <p className="text fz15">
                        We do not knowingly collect personal information from
                        minors under the age of 16 through our Service, although
                        certain third-party websites that we link to may do so.
                        These third-party websites have their own terms of use
                        and privacy policies and We encourage parents and legal
                        guardians to monitor their children's Internet usage and
                        instruct their children to never provide information on
                        other websites without their permission.
                      </p>
                      <p className="text fz15">
                        We do not sell the personal information of Consumers We
                        actually know are less than 16 years of age, unless We
                        receive affirmative authorization (the "right to
                        opt-in") from either the Consumer who is between 13 and
                        16 years of age, or the parent or guardian of a Consumer
                        less than 13 years of age. Consumers who opt-in to the
                        sale of personal information may opt-out of future sales
                        at any time. To exercise the right to opt-out, You (or
                        Your authorized representative) may submit a request to
                        Us by contacting Us.
                      </p>
                      <p className="text fz15">
                        If You have reason to believe that a child under the age
                        of 13 (or 16) has provided Us with personal information,
                        please contact Us with sufficient detail to enable Us to
                        delete that information.
                      </p>
                      <h6 className="mt5 mb5">
                        Your Rights under the CCPA/CPRA
                      </h6>
                      <p className="text fz15">
                        The CCPA/CPRA provides California residents with
                        specific rights regarding their personal information. If
                        You are a resident of California, You have the following
                        rights:
                        <ul>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            <strong className="mt5 mb5">
                              The right to notice.
                            </strong>{" "}
                            You have the right to be notified which categories
                            of Personal Data are being collected and the
                            purposes for which the Personal Data is being used.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            <strong className="mt5 mb5">
                              The right to know/access.
                            </strong>{" "}
                            Under CCPA/CPRA, You have the right to request that
                            We disclose information to You about Our collection,
                            use, sale, disclosure for business purposes, and
                            share of personal information. Once We receive and
                            confirm Your request, We will disclose to You:
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            The categories of personal information We collected
                            about You
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            The categories of sources for the personal
                            information We collected about You
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Our business or commercial purposes for collecting
                            or selling that personal information
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            The categories of third parties with whom We share
                            that personal information
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            The specific pieces of personal information We
                            collected about You
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            If we sold Your personal information or disclosed
                            Your personal information for a business purpose, We
                            will disclose to You:
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            The categories of personal information categories
                            sold
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            The categories of personal information categories
                            disclosed
                          </li>

                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            <strong className="mt5 mb5">
                              The right to say no to the sale or sharing of
                              Personal Data (opt-out).
                            </strong>
                            You have the right to direct Us to not sell Your
                            personal information. To submit an opt-out request,
                            please see the "Do Not Sell My Personal Information"
                            section or contact Us.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            <strong className="mt5 mb5">
                              The right to correct Personal Data.
                            </strong>{" "}
                            You have the right to correct or rectify any
                            inaccurate personal information about You that We
                            collected. Once We receive and confirm Your request,
                            We will use commercially reasonable efforts to
                            correct (and direct our Service Providers to
                            correct) Your personal information, unless an
                            exception applies.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            <strong className="mt5 mb5">
                              {" "}
                              The right to limit use and disclosure of sensitive
                              Personal Data.{" "}
                            </strong>
                            You have the right to request to limit the use or
                            disclosure of certain sensitive personal information
                            We collected about You, unless an exception applies.
                            To submit, please see the "Limit the Use or
                            Disclosure of My Sensitive Personal Information"
                            section or contact Us.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            <strong className="mt5 mb5">
                              {" "}
                              The right to delete Personal Data.{" "}
                            </strong>
                            You have the right to request the deletion of Your
                            Personal Data under certain circumstances, subject
                            to certain exceptions. Once We receive and confirm
                            Your request, We will delete (and direct Our Service
                            Providers to delete) Your personal information from
                            our records, unless an exception applies. We may
                            deny Your deletion request if retaining the
                            information is necessary for Us or Our Service
                            Providers to:
                          </li>

                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Complete the transaction for which We collected the
                            personal information, provide a good or service that
                            You requested, take actions reasonably anticipated
                            within the context of our ongoing business
                            relationship with You, or otherwise perform our
                            contract with You.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Detect security incidents, protect against
                            malicious, deceptive, fraudulent, or illegal
                            activity, or prosecute those responsible for such
                            activities.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Debug products to identify and repair errors that
                            impair existing intended functionality.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Exercise free speech, ensure the right of another
                            consumer to exercise their free speech rights, or
                            exercise another right provided for by law.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Comply with the California Electronic Communications
                            Privacy Act (Cal. Penal Code § 1546 et. seq.).
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Engage in public or peer-reviewed scientific,
                            historical, or statistical research in the public
                            interest that adheres to all other applicable ethics
                            and privacy laws, when the information's deletion
                            may likely render impossible or seriously impair the
                            research's achievement, if You previously provided
                            informed consent.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Enable solely internal uses that are reasonably
                            aligned with consumer expectations based on Your
                            relationship with Us.
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span> Comply
                            with a legal obligation.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Make other internal and lawful uses of that
                            information that are compatible with the context in
                            which You provided it.
                          </li>

                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            <strong className="mt5 mb5">
                              The right not to be discriminated against.
                            </strong>{" "}
                            You have the right not to be discriminated against
                            for exercising any of Your consumer's rights,
                            including by:
                          </li>

                          <li>
                            <span className="fz20 mr10">&#x2022;</span>Denying
                            goods or services to You
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Charging different prices or rates for goods or
                            services, including the use of discounts or other
                            benefits or imposing penalties
                          </li>
                          <li>
                            Providing a different level or quality of goods or
                            services to You
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Suggesting that You will receive a different price
                            or rate for goods or services or a different level
                            or quality of goods or services
                          </li>
                        </ul>
                      </p>
                      <h6 className="mt5 mb5">
                        Exercising Your CCPA/CPRA Data Protection Rights
                      </h6>
                      <p className="text fz15">
                        Please see the "Do Not Sell My Personal Information"
                        section and "Limit the Use or Disclosure of My Sensitive
                        Personal Information" section for more information on
                        how to opt out and limit the use of sensitive
                        information collected.
                      </p>
                      <p className="text fz15">
                        Additionally, in order to exercise any of Your rights
                        under the CCPA/CPRA, and if You are a California
                        resident, You can contact Us:
                      </p>
                      <ul>
                        <li>
                          By email:
                          <a href="topshotconnect@gmail.com">
                            topshotconnect@gmail.com
                          </a>
                        </li>
                        <li>
                          By visiting this page on our website:
                          <a href=" www.topshotphotographer.com">
                            www.topshotphotographer.com
                          </a>
                        </li>
                      </ul>
                      <p className="text fz15">
                        Only You, or a person registered with the California
                        Secretary of State that You authorize to act on Your
                        behalf, may make a verifiable request related to Your
                        personal information.
                      </p>
                      <p className="text fz15">
                        Your request to Us must:
                        <ul>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Provide sufficient information that allows Us to
                            reasonably verify You are the person about whom We
                            collected personal information or an authorized
                            representative
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Describe Your request with sufficient detail that
                            allows Us to properly understand, evaluate, and
                            respond to it
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        We cannot respond to Your request or provide You with
                        the required information if We cannot:
                        <ul>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Verify Your identity or authority to make the
                            request
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            Confirm that the personal information relates to You
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        We will disclose and deliver the required information
                        free of charge within 45 days of receiving Your
                        verifiable request. The time period to provide the
                        required information may be extended once by an
                        additional 45 days when reasonably necessary and with
                        prior notice.
                      </p>
                      <p className="text fz15">
                        Any disclosures We provide will only cover the 12-month
                        period preceding the verifiable request's receipt.
                      </p>
                      <p className="text fz15">
                        For data portability requests, We will select a format
                        to provide Your personal information that is readily
                        usable and should allow You to transmit the information
                        from one entity to another entity without hindrance.
                      </p>
                      <h6 className="mt5 mb5">
                        Do Not Sell My Personal Information
                      </h6>
                      <p className="text fz15">
                        As defined in the CCPA/CPRA, "sell" and "sale" mean
                        selling, renting, releasing, disclosing, disseminating,
                        making available, transferring, or otherwise
                        communicating orally, in writing, or by electronic or
                        other means, a Consumer's personal information by the
                        Business to a third party for valuable consideration.
                        This means that We may have received some kind of
                        benefit in return for sharing personal information, but
                        not necessarily a monetary benefit.
                      </p>
                      <p className="text fz15">
                        We do not sell personal information as the term sell is
                        commonly understood. We do allow Service Providers to
                        use Your personal information for the business purposes
                        described in Our Privacy Policy, for activities such as
                        advertising, marketing, and analytics, and these may be
                        deemed a sale under CCPA/CPRA.
                      </p>
                      <p className="text fz15">
                        You have the right to opt-out of the sale of Your
                        personal information. Once We receive and confirm a
                        verifiable consumer request from You, we will stop
                        selling Your personal information. To exercise Your
                        right to opt-out, please contact Us.
                      </p>
                      <p className="text fz15">
                        The Service Providers we partner with (for example, our
                        analytics or advertising partners) may use technology on
                        the Service that sells personal information as defined
                        by the CCPA/CPRA law. If you wish to opt out of the use
                        of Your personal information for interest-based
                        advertising purposes and these potential sales as
                        defined under CCPA/CPRA law, you may do so by following
                        the instructions below.
                      </p>
                      <p className="text fz15">
                        Please note that any opt out is specific to the browser
                        You use. You may need to opt out on every browser that
                        You use.
                      </p>
                      <p className="text fz15">
                        <strong className="mt5 mb5">Website</strong>
                        <br />
                        If applicable, click "Privacy Preferences", "Update
                        Privacy Preferences" or "Do Not Sell My Personal
                        Information" buttons listed on the Service to review
                        Your privacy preferences and opt out of cookies and
                        other technologies that We may use. Please note that You
                        will need to opt out from each browser that You use to
                        access the Service.
                      </p>
                      <p className="text fz15">
                        Additionally, You can opt out of receiving ads that are
                        personalized as served by our Service Providers by
                        following our instructions presented on the Service:
                        <ul>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            The NAI's opt-out platform:{" "}
                            <a
                              href="http://www.networkadvertising.org/choices/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              http://www.networkadvertising.org/choices/
                            </a>
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            The EDAA's opt-out platform:{" "}
                            <a
                              href="http://www.youronlinechoices.com/"
                              target="_blank"
                              rel="noreferrer"
                            >
                              http://www.youronlinechoices.com/
                            </a>
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            The DAA's opt-out platform:{" "}
                            <a
                              href="http://optout.aboutads.info/?c=2&lang=EN"
                              target="_blank"
                              rel="noreferrer"
                            >
                              http://optout.aboutads.info/?c=2&lang=EN
                            </a>
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        The opt-out will place a cookie on Your computer that is
                        unique to the browser You use to opt out. If you change
                        browsers or delete the cookies saved by Your browser,
                        You will need to opt out again.
                      </p>
                      <h6 className="mt5 mb5">Mobile Devices</h6>
                      <p className="text fz15">
                        Your mobile device may give You the ability to opt out
                        of the use of information about the apps You use in
                        order to serve You ads that are targeted to Your
                        interests:
                        <ul>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            "Opt out of Interest-Based Ads" or "Opt out of Ads
                            Personalization" on Android devices
                          </li>
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>
                            "Limit Ad Tracking" on iOS devices
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        You can also stop the collection of location information
                        from Your mobile device by changing the preferences on
                        Your mobile device.
                      </p>
                      <h6 className="mt5 mb5">
                        Limit the Use or Disclosure of My Sensitive Personal
                        Information
                      </h6>
                      <p className="text fz15">
                        If You are a California resident, You have the right to
                        limit the use and disclosure of Your sensitive personal
                        information to that use which is necessary to perform
                        the services or provide the goods reasonably expected by
                        an average consumer who requests such services or goods.
                      </p>
                      <p className="text fz15">
                        We collect, use and disclose sensitive personal
                        information in ways that are necessary to provide the
                        Service. For more information on how We use Your
                        personal information, please see the "Use of Your
                        Personal Data" section or contact us.
                      </p>
                      <h6 className="mt5 mb5">
                        "Do Not Track" Policy as Required by California Online
                        Privacy Protection Act (CalOPPA)
                      </h6>
                      <p className="text fz15">
                        Our Service does not respond to Do Not Track signals.
                        However, some third party websites do keep track of Your
                        browsing activities. If You are visiting such websites,
                        You can set Your preferences in Your web browser to
                        inform websites that You do not want to be tracked. You
                        can enable or disable DNT by visiting the preferences or
                        settings page of Your web browser.
                      </p>
                      <h6 className="mt5 mb5">
                        Your California Privacy Rights (California's Shine the
                        Light law)
                      </h6>
                      <p className="text fz15">
                        Under California Civil Code Section 1798 (California's
                        Shine the Light law), California residents with an
                        established business relationship with us can request
                        information once a year about sharing their Personal
                        Data with third parties for the third parties' direct
                        marketing purposes.
                      </p>
                      <p className="text fz15">
                        If you'd like to request more information under the
                        California Shine the Light law, and if You are a
                        California resident, You can contact Us using the
                        contact information provided below.
                      </p>
                      <h6 className="mt5 mb5">
                        California Privacy Rights for Minor Users (California
                        Business and Professions Code Section 22581)
                      </h6>
                      <p className="text fz15">
                        California Business and Professions Code Section 22581
                        allows California residents under the age of 18 who are
                        registered users of online sites, services or
                        applications to request and obtain removal of content or
                        information they have publicly posted.
                      </p>
                      <p className="text fz15">
                        To request removal of such data, and if You are a
                        California resident, You can contact Us using the
                        contact information provided below, and include the
                        email address associated with Your account.
                      </p>
                      <p className="text fz15">
                        Be aware that Your request does not guarantee complete
                        or comprehensive removal of content or information
                        posted online and that the law may not permit or require
                        removal in certain circumstances.
                      </p>
                      <h6 className="mt5 mb5">Children's Privacy</h6>
                      <p className="text fz15">
                        Our Service does not address anyone under the age of 13.
                        We do not knowingly collect personally identifiable
                        information from anyone under the age of 13. If You are
                        a parent or guardian and You are aware that Your child
                        has provided Us with Personal Data, please contact Us.
                        If We become aware that We have collected Personal Data
                        from anyone under the age of 13 without verification of
                        parental consent, We take steps to remove that
                        information from Our servers.
                      </p>
                      <p className="text fz15">
                        If We need to rely on consent as a legal basis for
                        processing Your information and Your country requires
                        consent from a parent, We may require Your parent's
                        consent before We collect and use that information.
                      </p>
                      <h6 className="mt5 mb5">Links to Other Websites</h6>
                      <p className="text fz15">
                        Our Service may contain links to other websites that are
                        not operated by Us. If You click on a third party link,
                        You will be directed to that third party's site. We
                        strongly advise You to review the Privacy Policy of
                        every site You visit.
                      </p>
                      <p className="text fz15">
                        We have no control over and assume no responsibility for
                        the content, privacy policies or practices of any third
                        party sites or services.
                      </p>
                      <h6 className="mt5 mb5">
                        Changes to this Privacy Policy
                      </h6>
                      <p className="text fz15">
                        We may update Our Privacy Policy from time to time. We
                        will notify You of any changes by posting the new
                        Privacy Policy on this page.
                      </p>
                      <p className="text fz15">
                        We will let You know via email and/or a prominent notice
                        on Our Service, prior to the change becoming effective
                        and update the "Last updated" date at the top of this
                        Privacy Policy.
                      </p>
                      <p className="text fz15">
                        You are advised to review this Privacy Policy
                        periodically for any changes. Changes to this Privacy
                        Policy are effective when they are posted on this page.
                      </p>
                      <h6 className="mt5 mb5">Contact Us</h6>
                      <p className="text fz15">
                        If you have any questions about this Privacy Policy, You
                        can contact us:
                        <ul>
                          <li>
                            Email:{" "}
                            <a href="mailto:topshotconnect@gmail.com">
                              topshotconnect@gmail.com
                            </a>
                          </li>
                          <li>
                            By visiting this page on our website:{" "}
                            <a
                              href="http://www.topshotphotographer.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              www.topshotphotographer.com
                            </a>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
