import { useForm } from "react-hook-form";
import { Col, Form, Modal } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import { useEffect, useState } from "react";
import { Status } from "../../constants/constant";
import { jobProposalList, updateJobStatus } from "../../API/JobsApi";
import { ErrorToaster, SuccessToaster } from "../Toasters/toaster";
import { reviewJobPoster } from "../../API/PhotographerApi";
import SelectField from "../SelectField/SelectField";
import InputField from "../InputField/InputField";

export function FeedBackModal(props) {
  const { show, onClose, jobId, reviewer, jobPosterId, setIsJobClosed } = props;
  const [status, setStatus] = useState("");
  const [ratingValue, setRatingValue] = useState(0);
  const [proposalList, setProposalList] = useState([]);

  const getJobProposals = async () => {
    setStatus(Status.PENDING);
    const formData = {
      jobId: jobId,
      status: Status.ACCEPTED,
    };
    try {
      const res = await jobProposalList(formData);
      setProposalList(res);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
    }
  };

  const changeJobStatus = async (formData) => {
    setStatus(Status.PENDING);
    try {
      await updateJobStatus(formData);
      SuccessToaster("Job closed successfully");
      setStatus(Status.IDLE);
      onClose();
      setIsJobClosed?.(true);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
    }
  };

  const giveJobPosterFeedBack = async (formData) => {
    setStatus(Status.PENDING);
    try {
      await reviewJobPoster(formData);
      SuccessToaster("Feedback submitted successfully.");
      setStatus(Status.IDLE);
      onClose();
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
    }
  };

  useEffect(() => {
    if (jobId) {
      getJobProposals();
    }
  }, [jobId]);

  const {
    register,
    control,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (ratingValue === 0) {
      setError("rating", { type: "custom", message: "Rating is required" });
    }
    if (reviewer !== "photographer") {
      const formData = {
        jobId: jobId,
        status: Status.COMPLETED,
        review: {
          photographerId: data?.photographer,
          rating: ratingValue,
          message: data?.feedback,
        },
      };
      await changeJobStatus(formData);
    } else {
      const formData = {
        jobId: jobId,
        userId: jobPosterId,
        rating: ratingValue,
        message: data?.feedback,
      };
      await giveJobPosterFeedBack(formData);
    }
    reset();
  };

  return (
    <Modal centered size="lg" backdrop="static" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title className="heading-color ff-heading">Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row align-items-center">
            {reviewer !== "photographer" && (
              <SelectField
                label="Select Photographer"
                name="photographer"
                options={proposalList?.map((item) => ({
                  label: item.name,
                  value: item.userId,
                }))}
                register={register}
                control={control}
                errorMessage="Photographer is required"
                errors={errors}
                required
                noOptionsMessage="No Photographer found"
                placeholder="Please select photographer"
              />
            )}
          </div>
          <div className="form-group">
            <div className="mb10 mt10">
              <label className="heading-color ff-heading fw500 mb10">
                Rating:
                <span className="required-field">*</span>
              </label>
              <Rating
                value={ratingValue}
                onClick={(value) => setRatingValue(value)}
                size={25}
                transition
                allowHalfIcon
                style={{ marginLeft: "10px" }}
              />
            </div>
          </div>
          {errors && (
            <Form.Text className="text-danger">
              {errors["rating"]?.message}
            </Form.Text>
          )}
          <Col md={12} className="mt-4">
            <InputField
              name="feedback"
              label="Feedback:"
              register={register}
              required
              type="text"
              rows={5}
              cols={50}
              as="textarea"
              errorMessage="Feedback is required"
              error={errors["feedback"]?.message}
              validation={500}
              maxLength={500}
            />
          </Col>

          <div className="modal-footer">
            <button type="submit" className="ud-btn btn-thm feedback">
              Submit
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
