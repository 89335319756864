import React from "react";
import { termsAndConditions } from "../../dummyData";

const tabs = [
  { id: "acknowledgement", label: "Acknowledgement and Acceptance" },
  { id: "about", label: "About Top Shot Photographer:" },
  { id: "definitions", label: "Interpretation and Definitions" },
  { id: "subscriptions", label: "Subscriptions" },
  { id: "content", label: "Content" },
  { id: "copyright", label: "Copyright Policy" },
  { id: "property", label: "Intellectual Property" },
  { id: "feedback", label: "Your Feedback to Us" },
  { id: "links", label: "Links to Other Websites" },
  { id: "termination", label: "Termination" },
  { id: "liability", label: "Limitation of Liability" },
  { id: "severability", label: "Severability and Waiver" },
  {
    id: "amendments",
    label: "Amendments & Modifications to These Terms and Conditions",
  },
  { id: "disclaimer", label: "Disclaimer" },
  { id: "contact", label: "Contact Us" },
];

const TermsAndConditions = () => {
  return (
    <div className="body_content">
      <section className="our-terms">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="main-title">
                <h2>Terms and Conditions</h2>
                <p className="text">
                  Welcome to Topshot Photographer! These Terms and Conditions
                  ("Terms") govern your use of our platform. By accessing or
                  using our platform, you agree to comply with these Terms.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-2">
              <div className="terms_condition_widget mb30-sm">
                <div className="widget_list">
                  <nav>
                    <div
                      className="nav nav-tabs text-start"
                      id="nav-tab"
                      role="tablist"
                    >
                      {tabs.map((tab, index) => (
                        <button
                          key={tab.id}
                          className={`nav-link text-start ${
                            index === 0 ? "active" : ""
                          }`}
                          id={`nav-${tab.id}-tab`}
                          data-bs-toggle="tab"
                          data-bs-target={`#nav-${tab.id}`}
                          type="button"
                          role="tab"
                          aria-controls={`nav-${tab.id}`}
                          aria-selected={index === 0}
                        >
                          {tab.label}
                        </button>
                      ))}
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div className="col-md-9 col-lg-9 col-xl-9 offset-xl-1">
              <div className="terms_condition_grid text-start">
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-accountpayment"
                    role="tabpanel"
                    aria-labelledby="nav-accountpayment-tab"
                  >
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">
                        Acknowledgement and Acceptance
                      </h4>
                      <p className="mb25 text fz15">
                        The following Terms and Conditions apply to (“you”, the
                        “User” or “Customer,” or “Professional or Photographer")
                        that signs up and registers as (“you”, the “User” or
                        “Customer,” or “Professional or Photographer"). The
                        following Terms & Conditions govern the use of our
                        platform. By accessing and or using our platform, you
                        agree to comply with these Terms & Conditions in
                        addition to any website terms of use which may be
                        subject to change from time to time. These Terms and
                        Conditions contain important information about your
                        legal rights and obligations, and is a legally binding
                        agreement between you (“you”, the “User” or “Customer,”
                        or “Professional or Photographer") and
                        topshotphotographer.com or Top Shot Photographer LLC.
                      </p>
                      <p className="text fz15">
                        Please carefully review these Terms & Conditions before
                        using topshotphotographer.com. Through completion of the
                        Sign up and Registration Process on
                        topshotphotographer.com you agree to be bound by all
                        agreements which constitute Top Shot Photographer’s
                        Terms of Service, including compliance with the Privacy
                        Policy in addition to any and all other Terms &
                        Conditions or Privacy Policy terms which may be subject
                        to change from time to time.
                      </p>
                      <p className="text fz15">
                        You represent that you are over the age of 18. The
                        Company does not permit those under 18 to use the
                        Service.
                      </p>
                      <p className="text fz15">
                        By using our Website, you acknowledge that you have
                        read, understood, and agreed to the terms and conditions
                        outlined in this Agreement. If you do not accept these
                        terms, please do not use our Website or proceed with the
                        sign-up and registration process.
                      </p>
                      <p className="text fz15">
                        Any and All references to "you" "your” "We” “They” as
                        applicable, mean the “Person” “Persons” or
                        “Organizations” who access, use, and/or participate in
                        the Platform in any manner, and each of your heirs,
                        assigns, and successors. As mentioned If you use the
                        Platform on behalf of an entity, you represent and
                        warrant that you have the authority to bind that entity,
                        your acceptance of the Terms will be deemed an
                        acceptance by that entity, and "you" and "your" herein
                        will refer to that entity, its directors, officers,
                        employees, and agents.
                      </p>
                      <p className="text fz15">
                        Please read Our Privacy Policy carefully before using
                        Our Service. Our Privacy Policy describes Our policies
                        and procedures on the collection, use and disclosure of
                        Your personal information when You use the Application
                        or the Website and tells You about Your privacy rights
                        and how the law protects You.
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">
                        About Top Shot Photographer:
                      </h4>
                      <p className="mb25 text fz15">
                        At Topshot Photographer, we're on a mission to bridge
                        the gap between Photographers and Clients. Our
                        user-friendly platform fosters professional connections,
                        ensuring quality results for every project. We strive to
                        create an environment where photographers flourish and
                        clients find the best match suitable to their
                        professional needs. You May Contact us at
                        topshotconnect@gmail.com
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">
                        Interpretation and Definitions
                      </h4>
                      <h6 className="mt5 mb5">Interpretation</h6>
                      <p className="mb25 text fz15">
                        The words of which the initial letter is capitalized
                        have meanings defined under the following conditions.
                        The following definitions shall have the same meaning
                        regardless of whether they appear in singular or in
                        plural.
                      </p>
                      <h6 className="mb10">Definitions</h6>
                      <p className="text fz15">
                        <strong>Account</strong> means a unique account created
                        for You to access our Service or parts of our Service.
                      </p>

                      <p className="text fz15">
                        <strong>Country</strong> refers to: New York, United
                        States
                      </p>

                      <p className="text fz15">
                        <strong>Company</strong> (referred to as either "the
                        Company", "We", "Us" or "Our" in this Agreement) refers
                        to Top Shot Photographer LLC
                      </p>

                      <p className="text fz15">
                        <strong>Content</strong> refers to content such as text,
                        images, or other information that can be posted,
                        uploaded, linked to or otherwise made available by You,
                        regardless of the form of that content.
                      </p>

                      <p className="text fz15">
                        <strong>Device</strong> means any device that can access
                        the Service such as a computer, a cellphone or a digital
                        tablet.
                      </p>

                      <p className="text fz15">
                        <strong>Feedback</strong> means feedback, innovations or
                        suggestions sent by You regarding the attributes,
                        performance or features of our Service.
                      </p>

                      <p className="text fz15">
                        <strong>Free Trial</strong> refers to a limited period
                        of time that may be free when purchasing a Subscription.
                      </p>

                      <p className="text fz15">
                        <strong>Service</strong> refers to the Website.
                      </p>

                      <p className="text fz15">
                        <strong>Subscriptions</strong> refer to the services or
                        access to the Service offered on a subscription basis by
                        the Company to You.
                      </p>

                      <p className="text fz15">
                        <strong>Terms and Conditions</strong> (also referred as
                        "Terms of Use" or “Terms of Service”) mean these Terms
                        and Conditions that form the entire agreement between
                        You and the Company regarding the use of the Service.
                      </p>

                      <p className="text fz15">
                        <strong>Third-party Social Media Service</strong> means
                        any services or content (including data, information,
                        products or services) provided by a third-party that may
                        be displayed, included or made available by the Service.
                      </p>

                      <p className="text fz15">
                        <strong>Website</strong> refers to Top Shot
                        Photographer, accessible from
                        www.topshotphotographer.com
                      </p>

                      <p className="text fz15">
                        <strong>You</strong> means the individual accessing or
                        using the Service, or the company, or other legal entity
                        on behalf of which such individual is accessing or using
                        the Service, as applicable.
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Subscriptions</h4>
                      <h6 className="mt5 mb5">Subscription period</h6>
                      <p className="text fz15">
                        The Service or some parts of the Service are available
                        only with a paid Subscription. You will be billed in
                        advance on a recurring and periodic basis (such as
                        daily, weekly, monthly or annually), depending on the
                        type of Subscription plan you select when purchasing the
                        Subscription.
                      </p>
                      <p className="text fz15">
                        At the end of each period, Your Subscription will
                        automatically renew under the exact same conditions
                        unless You cancel it or the Company cancels it.
                      </p>
                      <h6 className="mt5 mb5">Subscription cancellations</h6>
                      <p className="text fz15">
                        You may cancel Your Subscription renewal either through
                        Your Account settings page or by contacting the Company.
                        You will not receive a refund for the fees You already
                        paid for Your current Subscription period and You will
                        be able to access the Service until the end of Your
                        current Subscription period.
                      </p>

                      <h6 className="mt5 mb5">Billing</h6>
                      <p className="text fz15">
                        You shall provide the Company with accurate and complete
                        billing information including full name, address, state,
                        zip code, telephone number, and a valid payment method
                        information.
                      </p>
                      <p className="text fz15">
                        Should automatic billing fail to occur for any reason,
                        the Company will issue an electronic invoice indicating
                        that you must proceed manually, within a certain
                        deadline date, with the full payment corresponding to
                        the billing period as indicated on the invoice.
                      </p>

                      <h6 className="mt5 mb5">Fee Changes</h6>
                      <p className="text fz15">
                        The Company, in its sole discretion and at any time, may
                        modify the Subscription fees. Any Subscription fee
                        change will become effective at the end of the
                        then-current Subscription period.
                      </p>
                      <p className="text fz15">
                        The Company will provide You with reasonable prior
                        notice of any change in Subscription fees to give You an
                        opportunity to terminate Your Subscription before such
                        change becomes effective.
                      </p>
                      <p className="text fz15">
                        Your continued use of the Service after the Subscription
                        fee change comes into effect constitutes Your agreement
                        to pay the modified Subscription fee amount.
                      </p>

                      <h6 className="mt5 mb5">Refunds</h6>
                      <p className="text fz15">
                        Except when required by law, paid Subscription fees are
                        non-refundable.
                      </p>
                      <p className="text fz15">
                        Certain refund requests for Subscriptions may be
                        considered by the Company on a case-by-case basis and
                        granted at the sole discretion of the Company.
                      </p>

                      <h6 className="mt5 mb5">Free Trial</h6>
                      <p className="text fz15">
                        The Company may, at its sole discretion, offer a
                        Subscription with a Free Trial for a limited period of
                        time.
                      </p>
                      <p className="text fz15">
                        You may be required to enter Your billing information in
                        order to sign up for the Free Trial.
                      </p>
                      <p className="text fz15">
                        If You do enter Your billing information when signing up
                        for a Free Trial, You will not be charged by the Company
                        until the Free Trial has expired. On the last day of the
                        Free Trial period, unless You canceled Your
                        Subscription, You will be automatically charged the
                        applicable Subscription fees for the type of
                        Subscription You have selected.
                      </p>
                      <p className="text fz15">
                        At any time and without notice, the Company reserves the
                        right to (i) modify the terms and conditions of the Free
                        Trial offer, or (ii) cancel such Free Trial offer.
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">User Accounts</h4>

                      <p className="text fz15">
                        When You create an account with Us, You must provide Us
                        with information that is accurate, complete, and current
                        at all times. Failure to do so constitutes a breach of
                        the Terms, which may result in immediate termination of
                        Your account on Our Service.
                      </p>
                      <p className="text fz15">
                        You are responsible for safeguarding the password that
                        You use to access the Service and for any activities or
                        actions under Your password, whether Your password is
                        with Our Service or a Third-Party Social Media Service.
                      </p>
                      <p className="text fz15">
                        You agree not to disclose Your password to any third
                        party. You must notify Us immediately upon becoming
                        aware of any breach of security or unauthorized use of
                        Your account.
                      </p>
                      <p className="text fz15">
                        You may not use as a username the name of another person
                        or entity or that is not lawfully available for use, a
                        name or trademark that is subject to any rights of
                        another person or entity other than You without
                        appropriate authorization, or a name that is otherwise
                        offensive, vulgar or obscene.
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Content</h4>
                      <h6 className="mt5 mb5">Your Right to Post Content</h6>
                      <p className="text fz15">
                        Our Service allows You to post Content. You are
                        responsible for the Content that You post to the
                        Service, including its legality, reliability, and
                        appropriateness.
                      </p>
                      <p className="text fz15">
                        By posting Content to the Service, You grant Us the
                        right and license to use, modify, publicly perform,
                        publicly display, reproduce, and distribute such Content
                        on and through the Service. You retain any and all of
                        Your rights to any Content You submit, post, or display
                        on or through the Service and You are responsible for
                        protecting those rights. You agree that this license
                        includes the right for Us to make Your Content available
                        to other users of the Service, who may also use Your
                        Content subject to these Terms.
                      </p>
                      <p className="text fz15">
                        You represent and warrant that: (i) the Content is Yours
                        (You own it) or You have the right to use it and grant
                        Us the rights and license as provided in these Terms,
                        and (ii) the posting of Your Content on or through the
                        Service does not violate the privacy rights, publicity
                        rights, copyrights, contract rights or any other rights
                        of any person.
                      </p>
                      <h6 className="mt5 mb5">Content Restrictions</h6>
                      <p className="text fz15">
                        The Company is not responsible for the content of the
                        Service's users. You expressly understand and agree that
                        You are solely responsible for the Content and for all
                        activity that occurs under Your account, whether done so
                        by You or any third person using Your account.
                      </p>
                      <p className="text fz15">
                        You may not transmit any Content that is unlawful,
                        offensive, upsetting, intended to disgust, threatening,
                        libelous, defamatory, obscene or otherwise
                        objectionable. Examples of such objectionable Content
                        include, but are not limited to, the following:
                        <ul
                          style={{ listStyleType: "disc", marginLeft: "20px" }}
                        >
                          <li>
                            <span className="fz20 mr10">&#x2022;</span>Unlawful
                            or promoting unlawful activity.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Defamatory, discriminatory, or mean-spirited
                            content, including references or commentary about
                            religion, race, sexual orientation, gender,
                            national/ethnic origin, or other targeted groups.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Spam, machine – or randomly – generated,
                            constituting unauthorized or unsolicited
                            advertising, chain letters, any other form of
                            unauthorized solicitation, or any form of lottery or
                            gambling.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Containing or installing any viruses, worms,
                            malware, trojan horses, or other content that is
                            designed or intended to disrupt, damage, or limit
                            the functioning of any software, hardware or
                            telecommunications equipment or to damage or obtain
                            unauthorized access to any data or other information
                            of a third person.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Infringing on any proprietary rights of any party,
                            including patent, trademark, trade secret,
                            copyright, right of publicity or other rights.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Impersonating any person or entity including the
                            Company and its employees or representatives.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>{" "}
                            Violating the privacy of any third person.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span> False
                            information and features.
                          </li>
                        </ul>
                      </p>
                      .
                      <p className="text fz15">
                        The Company reserves the right, but not the obligation,
                        to, in its sole discretion, determine whether or not any
                        Content is appropriate and complies with these Terms,
                        refuse or remove this Content. The Company further
                        reserves the right to make formatting and edits and
                        change the manner of any Content. The Company can also
                        limit or revoke the use of the Service if You post such
                        objectionable Content. As the Company cannot control all
                        content posted by users and/or third parties on the
                        Service, you agree to use the Service at your own risk.
                        You understand that by using the Service You may be
                        exposed to content that You may find offensive,
                        indecent, incorrect or objectionable, and You agree that
                        under no circumstances will the Company be liable in any
                        way for any content, including any errors or omissions
                        in any content, or any loss or damage of any kind
                        incurred as a result of your use of any content.
                      </p>
                      <h6 className="mt5 mb5">Content Backups</h6>
                      <p className="text fz15">
                        Although regular backups of Content are performed, the
                        Company does not guarantee there will be no loss or
                        corruption of data.
                      </p>
                      <p className="text fz15">
                        Corrupt or invalid backup points may be caused by,
                        without limitation, Content that is corrupted prior to
                        being backed up or that changes during the time a backup
                        is performed.
                      </p>
                      <p className="text fz15">
                        The Company will provide support and attempt to
                        troubleshoot any known or discovered issues that may
                        affect the backups of Content. But You acknowledge that
                        the Company has no liability related to the integrity of
                        Content or the failure to successfully restore Content
                        to a usable state.
                      </p>
                      <p className="text fz15">
                        You agree to maintain a complete and accurate copy of
                        any Content in a location independent of the Service.
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Copyright Policy</h4>
                      <h6 className="mt5 mb5">
                        Intellectual Property Infringement
                      </h6>
                      <p className="text fz15">
                        We respect the intellectual property rights of others.
                        It is Our policy to respond to any claim that Content
                        posted on the Service infringes a copyright or other
                        intellectual property infringement of any person.
                      </p>
                      <p className="text fz15">
                        If You are a copyright owner, or authorized on behalf of
                        one, and You believe that the copyrighted work has been
                        copied in a way that constitutes copyright infringement
                        that is taking place through the Service, You must
                        submit Your notice in writing to the attention of our
                        copyright agent via email at{" "}
                        <a href="mailto:topshotconnect@gmail.com">
                          topshotconnect@gmail.com
                        </a>{" "}
                        and include in Your notice a detailed description of the
                        alleged infringement.
                      </p>
                      <p className="text fz15">
                        You may be held accountable for damages (including costs
                        and attorneys' fees) for misrepresenting that any
                        Content is infringing Your copyright.
                      </p>

                      <h6 className="mt5 mb5">
                        DMCA Notice and DMCA Procedure for Copyright
                        Infringement Claims
                      </h6>
                      <p className="text fz15">
                        You may submit a notification pursuant to the Digital
                        Millennium Copyright Act (DMCA) by providing our
                        Copyright Agent with the following information in
                        writing (see 17 U.S.C 512(c)(3) for further detail):
                        <ul>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            An electronic or physical signature of the person
                            authorized to act on behalf of the owner of the
                            copyright's interest.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>A
                            description of the copyrighted work that You claim
                            has been infringed, including the URL (i.e., web
                            page address) of the location where the copyrighted
                            work exists or a copy of the copyrighted work.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Identification of the URL or other specific location
                            on the Service where the material that You claim is
                            infringing is located.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>
                            Your address, telephone number, and email address.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>A
                            statement by You that You have a good faith belief
                            that the disputed use is not authorized by the
                            copyright owner, its agent, or the law.
                          </li>
                          <li>
                            {" "}
                            <span className="fz20 mr10">&#x2022;</span>A
                            statement by You, made under penalty of perjury,
                            that the above information in Your notice is
                            accurate and that You are the copyright owner or
                            authorized to act on the copyright owner's behalf.
                          </li>
                        </ul>
                      </p>
                      <p className="text fz15">
                        You can contact our copyright agent via email at{" "}
                        <a href="mailto:topshotconnect@gmail.com">
                          topshotconnect@gmail.com
                        </a>
                        . Upon receipt of a notification, the Company will take
                        whatever action, in its sole discretion, it deems
                        appropriate, including removal of the challenged content
                        from the Service.
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Intellectual Property</h4>
                      <p className="text fz15">
                        The Service and its original content (excluding Content
                        provided by You or other users), features and
                        functionality are and will remain the exclusive property
                        of the Company and its licensors.
                        <p className="text fz15">
                          The Service is protected by copyright, trademark, and
                          other laws of both the Country and foreign countries.
                        </p>
                        <p className="text fz15">
                          Our trademarks and trade dress may not be used in
                          connection with any product or service without the
                          prior written consent of the Company.
                        </p>
                      </p>
                    </div>

                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Your Feedback to Us</h4>
                      <p className="text fz15">
                        You assign all rights, title and interest in any
                        Feedback You provide the Company. If for any reason such
                        assignment is ineffective, You agree to grant the
                        Company a non-exclusive, perpetual, irrevocable, royalty
                        free, worldwide right and license to use, reproduce,
                        disclose, sub-license, distribute, modify and exploit
                        such Feedback without restriction.
                      </p>
                    </div>

                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Links to Other Websites</h4>
                      <p className="text fz15">
                        <p className="text fz15">
                          Our Service may contain links to third-party web sites
                          or services that are not owned or controlled by the
                          Company.{" "}
                        </p>
                        <p className="text fz15">
                          {" "}
                          The Company has no control over, and assumes no
                          responsibility for, the content, privacy policies, or
                          practices of any third-party web sites or services.
                          You further acknowledge and agree that the Company
                          shall not be responsible or liable, directly or
                          indirectly, for any damage or loss caused or alleged
                          to be caused by or in connection with the use of or
                          reliance on any such content, goods or services
                          available on or through any such web sites or
                          services.
                        </p>
                        <p className="text fz15">
                          We strongly advise You to read the terms and
                          conditions and privacy policies of any third-party web
                          sites or services that You visit.
                        </p>
                      </p>
                    </div>

                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Termination</h4>
                      <p className="text fz15">
                        <p className="text fz15">
                          We may terminate or suspend Your Account immediately,
                          without prior notice or liability, for any reason
                          whatsoever, including without limitation if You breach
                          these Terms and Conditions.
                        </p>
                        <p className="text fz15">
                          {" "}
                          Upon termination, Your right to use the Service will
                          cease immediately. If You wish to terminate Your
                          Account, You may simply discontinue using the Service.
                        </p>
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Limitation of Liability</h4>
                      <p className="text fz15">
                        Notwithstanding any damages that You might incur, the
                        entire liability of the Company and any of its suppliers
                        under any provision of this Terms and Your exclusive
                        remedy for all of the foregoing shall be limited to the
                        amount actually paid by You through the Service or 100
                        USD if You haven't purchased anything through the
                        Service.{" "}
                      </p>
                      <p className="text fz15">
                        To the maximum extent permitted by applicable law, in no
                        event shall the Company or its suppliers be liable for
                        any special, incidental, indirect, or consequential
                        damages whatsoever (including, but not limited to,
                        damages for loss of profits, loss of data or other
                        information, for business interruption, for personal
                        injury, loss of privacy arising out of or in any way
                        related to the use of or inability to use the Service,
                        third-party software and/or third-party hardware used
                        with the Service, or otherwise in connection with any
                        provision of this Terms), even if the Company or any
                        supplier has been advised of the possibility of such
                        damages and even if the remedy fails of its essential
                        purpose.{" "}
                      </p>
                      <p className="text fz15">
                        Some states do not allow the exclusion of implied
                        warranties or limitation of liability for incidental or
                        consequential damages, which means that some of the
                        above limitations may not apply. In these states, each
                        party's liability will be limited to the greatest extent
                        permitted by law.
                      </p>

                      <h6 className="mt5 mb5">
                        "AS IS" and "AS AVAILABLE" Disclaimer
                      </h6>
                      <p className="text fz15">
                        The Service is provided to You "AS IS" and "AS
                        AVAILABLE" and with all faults and defects without
                        warranty of any kind. To the maximum extent permitted
                        under applicable law, the Company, on its own behalf and
                        on behalf of its Affiliates and its and their respective
                        licensors and service providers, expressly disclaims all
                        warranties, whether express, implied, statutory or
                        otherwise, with respect to the Service, including all
                        implied warranties of merchantability, fitness for a
                        particular purpose, title and non-infringement, and
                        warranties that may arise out of course of dealing,
                        course of performance, usage or trade practice. Without
                        limitation to the foregoing, the Company provides no
                        warranty or undertaking, and makes no representation of
                        any kind that the Service will meet Your requirements,
                        achieve any intended results, be compatible or work with
                        any other software, applications, systems or services,
                        operate without interruption, meet any performance or
                        reliability standards or be error free or that any
                        errors or defects can or will be corrected.{" "}
                      </p>
                      <p className="text fz15">
                        Without limiting the foregoing, neither the Company nor
                        any of the company's provider makes any representation
                        or warranty of any kind, express or implied: (i) as to
                        the operation or availability of the Service, or the
                        information, content, and materials or products included
                        thereon; (ii) that the Service will be uninterrupted or
                        error-free; (iii) as to the accuracy, reliability, or
                        currency of any information or content provided through
                        the Service; or (iv) that the Service, its servers, the
                        content, or e-mails sent from or on behalf of the
                        Company are free of viruses, scripts, trojan horses,
                        worms, malware, timebombs or other harmful components.
                      </p>
                      <p className="text fz15">
                        Some jurisdictions do not allow the exclusion of certain
                        types of warranties or limitations on applicable
                        statutory rights of a consumer, so some or all of the
                        above exclusions and limitations may not apply to You.
                        But in such a case the exclusions and limitations set
                        forth in this section shall be applied to the greatest
                        extent enforceable under applicable law.
                      </p>

                      <h6 className="mt5 mb5">Governing Law</h6>
                      <p className="text fz15">
                        The laws of the Country, excluding its conflicts of law
                        rules, shall govern this Terms and Your use of the
                        Service. Your use of the Application may also be subject
                        to other local, state, national, or international laws.
                      </p>

                      <h6 className="mt5 mb5">Disputes Resolution</h6>
                      <p className="text fz15">
                        If You have any concern or dispute about the Service,
                        You agree to first try to resolve the dispute informally
                        by contacting the Company.
                      </p>

                      <h6 className="mt5 mb5">
                        United States Federal Government End Use Provisions
                      </h6>
                      <p className="text fz15">
                        If You are a U.S. federal government end user, our
                        Service is a "Commercial Item" as that term is defined
                        at 48 C.F.R. §2.101.
                      </p>

                      <h6 className="mt5 mb5">
                        United States Legal Compliance
                      </h6>
                      <p className="text fz15">
                        You represent and warrant that (i) You are not located
                        in a country that is subject to the United States
                        government embargo, or that has been designated by the
                        United States government as a "terrorist supporting"
                        country, and (ii) You are not listed on any United
                        States government list of prohibited or restricted
                        parties.
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Severability and Waiver</h4>

                      <h6 className="mt5 mb5">Severability</h6>
                      <p className="text fz15">
                        If any provision of these Terms is held to be
                        unenforceable or invalid, such provision will be changed
                        and interpreted to accomplish the objectives of such
                        provision to the greatest extent possible under
                        applicable law and the remaining provisions will
                        continue in full force and effect.
                      </p>

                      <h6 className="mt5 mb5">Waiver</h6>
                      <p className="text fz15">
                        Except as provided herein, the failure to exercise a
                        right or to require performance of an obligation under
                        these Terms shall not affect a party's ability to
                        exercise such right or require such performance at any
                        time thereafter nor shall the waiver of a breach
                        constitute a waiver of any subsequent breach.
                      </p>

                      <h6 className="mt5 mb5">Translation Interpretation</h6>
                      <p className="text fz15">
                        These Terms and Conditions may have been translated if
                        We have made them available to You on our Service. You
                        agree that the original English text shall prevail in
                        the case of a dispute.
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">
                        Amendments & Modifications to These Terms and Conditions
                      </h4>
                      <p className="text fz15">
                        Top Shot Photographer may update or modify the Terms
                        from time to time. Any changes will be effective
                        immediately upon posting the updated Terms on the
                        platform. We reserve the right, at Our sole discretion,
                        to modify or replace these Terms at any time. If a
                        revision is material, We will make reasonable efforts to
                        provide at least 30 days' notice prior to any new terms
                        taking effect. What constitutes a material change will
                        be determined at Our sole discretion.
                      </p>
                      <p className="text fz15">
                        By continuing to access or use Our Service after those
                        revisions become effective, You agree to be bound by the
                        revised terms. If You do not agree to the new terms, in
                        whole or in part, please stop using the website and the
                        Service.
                      </p>
                    </div>
                    <div className="grids mb40 mb40-md">
                      <h4 className="title mb10">Disclaimer</h4>
                      <p className="text fz15 mb10">Last updated:</p>

                      <h6 className="mt5 mb5">
                        Interpretation and Definitions
                      </h6>
                      <br />
                      <h6 className="mt5 mb5">Interpretation</h6>
                      <p className="text fz15">
                        The words of which the initial letter is capitalized
                        have meanings defined under the following conditions.
                        The following definitions shall have the same meaning
                        regardless of whether they appear in singular or plural.
                      </p>

                      <h6 className="mt5 mb5">Definitions</h6>
                      <p className="text fz15">
                        For the purposes of this Disclaimer:
                      </p>
                      <ul className="text fz15">
                        <li>
                          {" "}
                          <span className="fz20 mr10">&#x2022;</span>
                          <strong>Company</strong> (referred to as either "the
                          Company", "We", "Us" or "Our" in this Disclaimer)
                          refers to Top Shot Photographer LLC.
                        </li>
                        <li>
                          {" "}
                          <span className="fz20 mr10">&#x2022;</span>
                          <strong>Service</strong> refers to the Website.
                        </li>
                        <li>
                          {" "}
                          <span className="fz20 mr10">&#x2022;</span>
                          <strong>You</strong> means the individual accessing
                          the Service, or the company, or other legal entity on
                          behalf of which such individual is accessing or using
                          the Service, as applicable.
                        </li>
                        <li>
                          {" "}
                          <span className="fz20 mr10">&#x2022;</span>
                          <strong>Website</strong> refers to Top Shot
                          Photographer, accessible from
                          www.topshotphotographer.com.
                        </li>
                      </ul>

                      <h6 className="mt5 mb5">Disclaimer</h6>
                      <p className="text fz15">
                        The information contained on the Service is for general
                        information purposes only.{" "}
                      </p>
                      <p className="text fz15">
                        The Company assumes no responsibility for errors or
                        omissions in the contents of the Service.
                      </p>

                      <p className="text fz15">
                        In no event shall the Company be liable for any special,
                        direct, indirect, consequential, or incidental damages
                        or any damages whatsoever, whether in an action of
                        contract, negligence or other tort, arising out of or in
                        connection with the use of the Service or the contents
                        of the Service. The Company reserves the right to make
                        additions, deletions, or modifications to the contents
                        on the Service at any time without prior notice. This
                        Disclaimer has been created with the help of the
                        <a href=""> Disclaimer Generator.</a>
                      </p>
                      <p className="text fz15">
                        The Company does not warrant that the Service is free of
                        viruses or other harmful components.
                      </p>

                      <h6 className="mt5 mb5">External Links Disclaimer</h6>
                      <p className="text fz15">
                        The Service may contain links to external websites that
                        are not provided or maintained by or in any way
                        affiliated with the Company.{" "}
                      </p>
                      <p className="text fz15">
                        Please note that the Company does not guarantee the
                        accuracy, relevance, timeliness, or completeness of any
                        information on these external websites.
                      </p>

                      <h6 className="mt5 mb5">
                        Errors and Omissions Disclaimer
                      </h6>
                      <p className="text fz15">
                        The information given by the Service is for general
                        guidance on matters of interest only. Even if the
                        Company takes every precaution to ensure that the
                        content of the Service is both current and accurate,
                        errors can occur. Plus, given the changing nature of
                        laws, rules and regulations, there may be delays,
                        omissions or inaccuracies in the information contained
                        on the Service.{" "}
                      </p>
                      <p className="text fz15">
                        The Company is not responsible for any errors or
                        omissions, or for the results obtained from the use of
                        this information.
                      </p>

                      <h6 className="mt5 mb5">Fair Use Disclaimer</h6>
                      <p className="text fz15">
                        The Company may use copyrighted material which has not
                        always been specifically authorized by the copyright
                        owner. The Company is making such material available for
                        criticism, comment, news reporting, teaching,
                        scholarship, or research.{" "}
                      </p>
                      <p className="text fz15">
                        The Company believes this constitutes a "fair use" of
                        any such copyrighted material as provided for in section
                        107 of the United States Copyright law.
                      </p>
                      <p className="text fz15">
                        If You wish to use copyrighted material from the Service
                        for your own purposes that go beyond fair use, You must
                        obtain permission from the copyright owner.
                      </p>

                      <h6 className="mt5 mb5">Views Expressed Disclaimer</h6>
                      <p className="text fz15">
                        The Service may contain views and opinions which are
                        those of the authors and do not necessarily reflect the
                        official policy or position of any other author, agency,
                        organization, employer or company, including the
                        Company.
                      </p>
                      <p className="text fz15">
                        Comments published by users are their sole
                        responsibility and the users will take full
                        responsibility, liability and blame for any libel or
                        litigation that results from something written in or as
                        a direct result of something written in a comment. The
                        Company is not liable for any comment published by users
                        and reserves the right to delete any comment for any
                        reason whatsoever.
                      </p>

                      <h6 className="mt5 mb5">No Responsibility Disclaimer</h6>
                      <p className="text fz15">
                        The information on the Service is provided with the
                        understanding that the Company is not herein engaged in
                        rendering legal, accounting, tax, or other professional
                        advice and services. As such, it should not be used as a
                        substitute for consultation with professional
                        accounting, tax, legal or other competent advisers.
                      </p>
                      <p className="text fz15">
                        In no event shall the Company or its suppliers be liable
                        for any special, incidental, indirect, or consequential
                        damages whatsoever arising out of or in connection with
                        your access or use or inability to access or use the
                        Service.
                      </p>

                      <h6 className="mt5 mb5">
                        "Use at Your Own Risk" Disclaimer
                      </h6>
                      <p className="text fz15">
                        All information in the Service is provided "as is", with
                        no guarantee of completeness, accuracy, timeliness or of
                        the results obtained from the use of this information,
                        and without warranty of any kind, express or implied,
                        including, but not limited to warranties of performance,
                        merchantability and fitness for a particular purpose.
                      </p>
                      <p className="text fz15">
                        The Company will not be liable to You or anyone else for
                        any decision made or action taken in reliance on the
                        information given by the Service or for any
                        consequential, special or similar damages, even if
                        advised of the possibility of such damages.
                      </p>

                      <h6 className="mt5 mb5">Contact Us</h6>
                      <p className="text fz15">
                        If you have any questions about this Disclaimer, You can
                        contact Us:
                      </p>
                      <ul className="text fz15">
                        <li>
                          {" "}
                          <span className="fz20 mr10">&#x2022;</span>By email:
                          topshotconnect@gmail.com
                        </li>
                        <li>
                          {" "}
                          <span className="fz20 mr10">&#x2022;</span>
                          By visiting this page on our website:{" "}
                          <a
                            href="http://www.topshotphotographer.com"
                            target="_blank"
                            rel="noreferrer"
                          >
                            www.topshotphotographer.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;
