import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { RangeSlider } from "rsuite";
import "rsuite/dist/rsuite.css";

const Price = ({ setFilterPrice, filterPrice }) => {
  const [searchParams] = useSearchParams();

  // Extract the 'category' parameter from the search parameters
  const selectedJobRateMax = searchParams.get("jobRateMax");
  const selectedJobRateMin = searchParams.get("jobRateMin");

  const [show, setShow] = useState(true);

  useEffect(() => {
    setFilterPrice({
      min: filterPrice?.min ?? selectedJobRateMin,
      max: filterPrice?.max ?? selectedJobRateMax,
    });
  }, [setFilterPrice, selectedJobRateMin, selectedJobRateMax]);

  const handleMinRangeChange = (e) => {
    const value = parseInt(e.target.value);
    setFilterPrice((prev) => ({ ...prev, min: value }));
  };

  const handleMaxRangeChange = (e) => {
    const value = parseInt(e.target.value);
    setFilterPrice((prev) => ({ ...prev, max: value }));
  };

  return (
    <div className="card mb20 pb10 mt-0">
      <div className={`${show ? "active" : ""}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}
          >
            Price
          </button>
        </h4>
      </div>
      <div
        id="collapse0"
        className={`collapse ${show ? "show" : ""}`}
        aria-labelledby="heading0"
        data-parent="#accordionExample"
        style={{
          width: "16rem",
          padding: "20px",
          marginTop: "-30px",
        }}
      >
        <br />
        <div className="text-center">
          <div
            className="d-flex justify-content-around"
            style={{
              display: "inline-block",
              marginRight: "20px",
              alignItems: "center",
            }}
          >
            <label
              htmlFor="min-input"
              style={{ display: "block", fontWeight: "bold" }}
            >
              Min
            </label>
            <input
              id="min-input"
              type="number"
              className="amount"
              maxLength={5}
              value={filterPrice?.min}
              style={{ marginLeft: "80px" }}
              onFocus={(e) => (e.target.style.border = "2px solid #007bff")}
              onBlur={(e) => (e.target.style.border = "1px solid #ccc")}
              onChange={handleMinRangeChange}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
                if (e.target.value.length > 5) {
                  e.target.value = e.target.value.slice(0, 5); // Trim to max length of 5
                }
              }}
            />
          </div>
          <div
            className="d-flex justify-content-around"
            style={{
              marginTop: "15px",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <label
              htmlFor="max-input"
              style={{ display: "block", fontWeight: "bold" }}
            >
              Max
            </label>
            <input
              id="max-input"
              type="number"
              className="amount2"
              maxLength={5}
              value={filterPrice?.max}
              style={{ marginLeft: "78px", alignItems: "center" }}
              onFocus={(e) => (e.target.style.border = "2px solid #007bff")}
              onBlur={(e) => (e.target.style.border = "1px solid #ccc")}
              onChange={handleMaxRangeChange}
              onInput={(e) => {
                if (e.target.value.length > 5) {
                  e.target.value = e.target.value.slice(0, 5); // Trim to max length of 5
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Price;
