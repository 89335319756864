import React, { useRef, useEffect } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import "./MapComponent.css"; // Import the CSS file for styling

function MapComponent({
  name,
  register,
  setValue,
  placeholder,
  setFilterLocation,
  required,
  value,
}) {
  const inputRef = useRef(null);

  const handlePlaceChanged = () => {
    const places = inputRef.current.getPlaces();
    if (places.length > 0) {
      const place = places[0];
      if (name === "filterLocation") {
        setFilterLocation(place?.formatted_address);
      } else {
        setValue(name, place?.formatted_address);
      }
    } else {
      setFilterLocation("");
      setValue("");
    }
  };

  const handleOnchange = (e) => {
    console.log(":dff", e.target.value);
    
    setFilterLocation?.(e.target.value);
    setValue(name,e.target.value);
  };

  const clearInput = () => {
    setFilterLocation?.("");
    setValue("");
  };

  useEffect(() => {
    setValue(name, value);
  }, [value, name, setValue]);

  useEffect(() => {
    // Remove the label with the class 'flaticon-loupe'
    const loupeLabel = document.querySelector("label .flaticon-loupe");
    if (loupeLabel) {
      loupeLabel.parentElement.remove();
    }
  }, []);
  return (
    <div className="input-container">
      <StandaloneSearchBox
        onLoad={(ref) => (inputRef.current = ref)}
        onPlacesChanged={handlePlaceChanged}
      >
        <input
          type="text"
          placeholder={placeholder}
          className="form-control"
          {...register(name, {
            ...(required && { required: "Location is required" }),
          })}
          onChange={handleOnchange}
          value={value}
          // style={{
          //   width: isDesktopOrLaptop ? `300px` : "366px",
          //   textOverflow: `ellipses`,
          // }}
        />
      </StandaloneSearchBox>
      {value && (
        <button
          type="button"
          className="clear-button"
          // style={{ marginTop: isDesktopOrLaptop ? "-50px" : "-58px" }}
          onClick={clearInput}
        >
          &times;
        </button>
      )}
    </div>
  );
}

export default React.memo(MapComponent);
