// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
  /* position: relative; */
  /* display: flex; */
  align-items: center;
  width: 100%;
}

.form-control {
  width: 100%;
  padding-right: 40px; /* Adjust padding to make space for the clear button */
}

.clear-button {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #aaa;
  padding: 5px;
  font-size: 24px;
  margin-top: -50px;
}

.clear-button:hover {
  color: #000;
}`, "",{"version":3,"sources":["webpack://./src/shared/MapComponent/MapComponent.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,mBAAmB,EAAE,sDAAsD;AAC7E;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,WAAW;EACX,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".input-container {\n  /* position: relative; */\n  /* display: flex; */\n  align-items: center;\n  width: 100%;\n}\n\n.form-control {\n  width: 100%;\n  padding-right: 40px; /* Adjust padding to make space for the clear button */\n}\n\n.clear-button {\n  position: absolute;\n  right: 10px;\n  background: none;\n  border: none;\n  font-size: 20px;\n  cursor: pointer;\n  color: #aaa;\n  padding: 5px;\n  font-size: 24px;\n  margin-top: -50px;\n}\n\n.clear-button:hover {\n  color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
