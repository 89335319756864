import React, { useEffect, useState } from "react";
import { levels, levelsOptions } from "../../../dummyData";
import { useSearchParams } from "react-router-dom";

const Levels = ({ setFilterLevel }) => {
  const [searchParams] = useSearchParams();

  const [show, setShow] = useState(true);
  const selectedLevel = searchParams.get("level");
  const [checkedLevel, setCheckedLevel] = useState(null);

  useEffect(() => {
    // setFilterLevel(selectedLevel);
    setCheckedLevel(selectedLevel);
  }, [selectedLevel]);

  const handleCheckboxChange = (level) => {
    if (checkedLevel === level) {
      setCheckedLevel(null);
      setFilterLevel(null); // Clear filter if the same level is clicked again
    } else {
      setCheckedLevel(level);
      setFilterLevel(level);
    }
  };

  return (
    <div className="card mb20 pb5">
      <div className={`${show ? "active" : ""}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0 pt-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}
          >
            Level
          </button>
        </h4>
      </div>
      <div
        id="collapse4"
        className={`collapse ${show ? "show" : ""}`}
        aria-labelledby="heading4"
        data-parent="#accordionExample"
      >
        <div className="card-body card-body px-0 pt-0">
          {levelsOptions.map(({ label, value }, index) => (
            <div key={index} className="checkbox-style1">
              <label className="custom_checkbox">
                {label}
                <input
                  type="checkbox"
                  checked={checkedLevel === value}
                  onChange={() => handleCheckboxChange(value)}
                />
                <span className="checkmark"></span>
                {/*<span className="right-tags">(1,945)</span>*/}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Levels;
