import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { categories } from "../../../dummyData";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { ErrorToaster, SuccessToaster } from "../../../shared/Toasters/toaster";
import { createJob } from "../../../API/JobsApi";
import { useNavigate } from "react-router-dom";
import { Status } from "../../../constants/constant";
import MapComponent from "../../../shared/MapComponent/MapComponent";
import TextEditor from "../../../shared/TextEditor";

const PostJob = () => {
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEndDateTime] = useState(null);
  const [status, setStatus] = useState("");
  // const [error, setError] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const budgetType = watch("budgetType");
  const durationType = watch("durationType");
  const jobDescription = watch("description");
  const jobLocation = watch("location");
  const jobDuration = watch("jobDuration");
  const jobType = watch("jobType");

  useEffect(() => {
    if (selectedStartDateTime && jobDuration && durationType) {
      let calculatedEndDate = new Date(selectedStartDateTime);
      if (durationType === "days") {
        calculatedEndDate.setDate(
          calculatedEndDate.getDate() + parseInt(jobDuration)
        );
      } else if (durationType === "hours") {
        calculatedEndDate.setHours(
          calculatedEndDate.getHours() + parseInt(jobDuration)
        );
      }
      setSelectedEndDateTime(calculatedEndDate);
    }
  }, [jobDuration, jobType, setValue, selectedStartDateTime, durationType]);

  const postJob = async (data) => {
    const startDateStr = selectedStartDateTime;
    const endDateStr = selectedEndDateTime;

    const startDateObj = new Date(startDateStr);
    const endDateObj = new Date(endDateStr);

    const startTimestamp = startDateObj.getTime();
    const endTimestamp = endDateObj.getTime();

    const formValue = {
      ...data,
      jobRate: budgetType === "notSure" ? 0 : parseInt(data.jobRate),
      jobDateTime: startTimestamp,
      jobEndDateTime: endTimestamp,
    };
    setStatus(Status.PENDING);
    try {
      const res = await createJob(formValue);
      if (res) {
        SuccessToaster("Job Created Successfully!");
        setStatus(Status.IDLE);
        reset();
        setTimeout(() => {
          navigate("/my-jobs");
        }, 2000);
      }
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
      // setError(error?.message);
    }
  };
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const filterEndTime = (time) => {
    if (!selectedStartDateTime) return true;
    const startDateTime = new Date(selectedStartDateTime);
    const currentTime = new Date(time);
    return currentTime > startDateTime;
  };

  const handleEditorChange = (content) => {
    if (content.length > 3000) {
      setError("description", {
        type: "manual",
        message: "Description should not exceed 3000 characters",
      });
    } else {
      clearErrors("description");
    }
    setValue("description", content);
  };

  const handleEditorInit = () => {
    const toolbar = document.querySelector(".tox-tinymce-aux");
    const modalBody = document.querySelector(".modal-body");
    if (toolbar && modalBody) {
      modalBody.appendChild(toolbar);
    }
  };

  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative mt10">
      <div className="col-lg-9">
        <form
          className="form-style1"
          noValidate
          onSubmit={handleSubmit(postJob)}
        >
          <div className="row">
            <div className="col-sm-6">
              <div className="mb20">
                <label className="heading-color ff-heading fw500 mb10">
                  Job Title
                  <span className="required-field">*</span>
                </label>
                <input
                  id="title"
                  {...register("title", {
                    required: "Title is required",
                  })}
                  type="text"
                  className="form-control"
                  placeholder="Example: Senior Fashion Photographer"
                />
                {errors.title && (
                  <p className="text-danger">{errors.title.message}</p>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1">
                  <label className="heading-color ff-heading fw500 mb10">
                    Please Specify How Many Photographers Are Required
                    <span className="required-field">*</span>
                  </label>
                  <input
                    {...register("noOfPhotographers", {
                      required: "No of Photographers is required",
                    })}
                    type="number"
                    className="form-control"
                    placeholder="Enter Number of Photographers Required"
                    onInput={(e) => {
                      if (e.target.value.length > 2) {
                        e.target.value = e.target.value.slice(0, 2); // Trim to max length of 3
                      }
                    }}
                  />
                  {errors.noOfPhotographers && (
                    <p className="text-danger">
                      {errors.noOfPhotographers.message}
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1">
                  <label className="heading-color ff-heading fw500 mb10">
                    Job Category
                    <span className="required-field">*</span>
                  </label>
                  <div className="bootselect-multiselect">
                    <select
                      className="form-control"
                      {...register("category", {
                        required: "Category is required",
                      })}
                      // onChange={handleChange}
                    >
                      <option value="" selected disabled>
                        Select Category
                        <span className="required-field">*</span>
                      </option>
                      {categories.map((category, index) => (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                  </div>
                  {errors.category && (
                    <p className="text-danger">{errors.category.message}</p>
                  )}
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1">
                  <label className="heading-color ff-heading fw500 mb10">
                    Job Location
                    <span className="required-field">*</span>
                  </label>
                  <MapComponent
                    name="location"
                    register={register}
                    setValue={setValue}
                    value={jobLocation}
                    // defaultValue={userInfo?.location}
                    placeholder="Enter Location (USA Only)"
                  />
                  {errors.location && (
                    <p className="text-danger">{errors.location.message}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb20 align-items-center">
                <label className="heading-color ff-heading fw500 mb10">
                  Budget Type
                  <span className="required-field">*</span>
                </label>
                <div className="input-group flex-grow-1">
                  <select
                    className="form-control"
                    id="budgetType"
                    {...register("budgetType", {
                      required: "Budget type is required",
                    })} // Replace 'yourSelectName' with your select/dropdown name
                  >
                    <option value="" selected disabled>
                      Select
                    </option>
                    <option value="hourly">Hourly</option>
                    <option value="fixed">Fixed</option>
                    <option value="notSure">Not Sure</option>
                  </select>
                </div>
                {errors.budgetType && (
                  <p className="text-danger">{errors.budgetType.message}</p>
                )}
              </div>
            </div>
            {budgetType === "hourly" ? (
              <div className="col-sm-6">
                <div className="mb20">
                  <div className="form-style1">
                    <label className="heading-color ff-heading fw500 mb10">
                      Hourly Rate
                      <span className="required-field">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <input
                        {...register("jobRate", {
                          required: "job Rate is required",
                          maxLength: {
                            value: 3,
                            message: "Job rate cannot exceed 3 digits",
                          },
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                        type="number"
                        className="form-control"
                        min="1"
                        max="999"
                        maxLength={3}
                        placeholder="Enter Hourly Rate"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ); // Remove non-numeric characters
                          if (e.target.value.length > 3) {
                            e.target.value = e.target.value.slice(0, 3); // Trim to max length of 3
                          }
                        }}
                      />
                    </div>
                    {errors.jobRate && (
                      <p className="text-danger">{errors.jobRate.message}</p>
                    )}
                  </div>
                </div>
              </div>
            ) : budgetType === "fixed" ? (
              <div className="col-sm-6">
                <div className="mb20">
                  <div className="form-style1">
                    <label className="heading-color ff-heading fw500 mb10">
                      Fixed Amount
                      <span className="required-field">*</span>
                    </label>
                    <div className="input-group">
                      <span className="input-group-text">$</span>
                      <input
                        {...register("jobRate", {
                          required: "job Rate is required",
                          maxLength: {
                            value: 5,
                            message:
                              "The budget can only be entered as a number with up to 5 digits.",
                          },
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Only numbers are allowed",
                          },
                        })}
                        type="number"
                        maxLength={3}
                        className="form-control"
                        placeholder="Enter Fixed Amount"
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          ); // Remove non-numeric characters
                          if (e.target.value.length > 5) {
                            e.target.value = e.target.value.slice(0, 5); // Trim to max length of 5
                          }
                        }}
                      />
                    </div>
                    {errors.jobRate && (
                      <p className="text-danger">{errors.jobRate.message}</p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-sm-6"></div>
            )}
            <div className="col-sm-6">
              <label className="heading-color ff-heading fw500 mb10">
                Shoot Duration Type
                <span className="required-field">*</span>
              </label>
              <div className="bootselect-multiselect">
                <select
                  className="form-control"
                  {...register("durationType", {
                    required: "Duration Type is required",
                  })}
                  // onChange={handleChange}
                >
                  <option value="" disabled selected>
                    Select Shoot Type
                    <span className="required-field">*</span>
                  </option>
                  <option value="days">Days</option>
                  <option value="hours">Hours</option>
                </select>
              </div>
              {errors.durationType && (
                <p className="text-danger">{errors.durationType.message}</p>
              )}
            </div>
            {durationType === "days" ? (
              <div className="col-sm-6">
                <label className="heading-color ff-heading fw500 mb10">
                  Shoot Duration
                  <span className="required-field">*</span>
                </label>
                <input
                  {...register("jobDuration", {
                    required: "Job Duration is required",
                    pattern: {
                      value: /^[1-9]\d*$/, // Regex to ensure positive integers only
                      message: "Enter a valid positive integer",
                    },
                  })}
                  type="number"
                  pattern="[0-9]*"
                  className="form-control"
                  placeholder="Enter shoot duration (Days)"
                  onInput={(e) => {
                    if (e.target.value.length > 2) {
                      e.target.value = e.target.value.slice(0, 2); // Trim to max length of 3
                    }
                  }}
                />
                {errors.jobDuration && (
                  <p className="text-danger">{errors.jobDuration.message}</p>
                )}
              </div>
            ) : durationType === "hours" ? (
              <div className="col-sm-6">
                <label className="heading-color ff-heading fw500 mb10">
                  Shoot Duration
                  <span className="required-field">*</span>
                </label>
                <input
                  {...register("jobDuration", {
                    required: "Job Duration is required",
                    min: { value: 1, message: "Minimum value is 1" },
                    max: {
                      value: 24,
                      message: "Enter value less than 24",
                    },
                    pattern: {
                      value: /^[1-9]\d*$/, // Regex to ensure positive integers only
                      message: "Enter a valid positive integer",
                    },
                  })}
                  type="number"
                  pattern="[0-9]*"
                  className="form-control"
                  placeholder="Enter shoot duration (Hours)"
                  onInput={(e) => {
                    if (e.target.value.length > 2) {
                      e.target.value = e.target.value.slice(0, 2); // Trim to max length of 3
                    }
                  }}
                />
                {errors.jobDuration && (
                  <p className="text-danger">{errors.jobDuration.message}</p>
                )}
              </div>
            ) : (
              <div className="col-sm-6"></div>
            )}

            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1" style={{ marginTop: "25px" }}>
                  <label className="heading-color ff-heading fw500 mb10">
                    Job Start Date & Time
                    <span className="required-field">*</span>
                  </label>
                  <br />
                  <DatePicker
                    selected={selectedStartDateTime}
                    onChange={(date) => setSelectedStartDateTime(date)}
                    showTimeSelect
                    timeFormat="hh:mm aa"
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="yyyy-MM-dd hh:mm aa"
                    className="form-control customDatePicker"
                    placeholderText="Select Start Date And Time"
                    minDate={new Date()}
                    filterTime={filterPassedTime}
                    required
                  />
                </div>
                {errors.jobDateTime && (
                  <p className="text-danger fw500">
                    {errors.jobDateTime.message}
                  </p>
                )}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="mb20">
                <div className="form-style1" style={{ marginTop: "25px" }}>
                  <label className="heading-color ff-heading fw500 mb10">
                    Job End Date & Time
                    <span className="required-field">*</span>
                  </label>
                  <br />
                  <DatePicker
                    selected={selectedEndDateTime}
                    onChange={(date) => setSelectedEndDateTime(date)}
                    showTimeSelect
                    timeFormat="hh:mm aa"
                    timeIntervals={30}
                    timeCaption="Time"
                    dateFormat="yyyy-MM-dd hh:mm aa"
                    minDate={selectedStartDateTime} // Set the minimum date to be the selected start date
                    className="form-control customDatePicker"
                    placeholderText="Select End Date And time"
                    filterTime={filterEndTime}
                    style={{ width: "380px" }}
                    disabled
                  />
                  {errors.jobEndDateTime && (
                    <p className="text-danger fw500">
                      {errors.jobEndDateTime.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                  Is Studio Space Required?
                  <input
                    type="checkbox"
                    id="multipleCameraRequired"
                    {...register("multipleCameraRequired")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                  Is Videography Required?
                  <input
                    type="checkbox"
                    id="videographyNeeded"
                    {...register("videographyNeeded")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                  Are digital files required?
                  <input
                    type="checkbox"
                    id="digitalFilesRequired"
                    {...register("digitalFilesRequired")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="custom_checkbox heading-color fw500 fz16 ff-heading">
                  Is photographer's business insurance required?
                  <input
                    type="checkbox"
                    id="businessInsuranceRequired"
                    {...register("businessInsuranceRequired")}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb10">
                <label className="heading-color ff-heading fw500 mb10">
                  Job Description
                  <span className="required-field">*</span>
                </label>
                {/* <textarea
                  {...register("description", {
                    required: "Job Description is required",
                  })}
                  cols="30"
                  rows="6"
                  placeholder="Write a job description..."
                /> */}
                <TextEditor
                  onChange={(tabId, content) => handleEditorChange(content)}
                  initialValue={jobDescription}
                  handleEditorInit={handleEditorInit}
                  setError={setError}
                  clearErrors={clearErrors}
                  label="description"
                />
                {errors.description && (
                  <p className="text-danger fw500">
                    {errors.description.message}
                  </p>
                )}
              </div>
            </div>

            <div className="col-md-12">
              <div className="text-start">
                <Button
                  className="ud-btn btn-thm"
                  type="submit"
                  // onClick={handleNextClick}
                  // disabled={
                  //   userInfo?.profileStatus === 'pending for review'
                  // }
                >
                  Post A Job
                  <i className="fal fa-arrow-right-long"></i>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PostJob;
