import React, { useState } from "react";
import { faqs } from "../../dummyData";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="our-faq pb50">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 m-auto wow fadeInUp" data-wow-delay="300ms">
            <div className="main-title text-center">
              <h2 className="title">Frequently Asked Questions</h2>
            </div>
          </div>
        </div>
        <div className="row wow fadeInUp" data-wow-delay="300ms">
          <div className="col-lg-8 mx-auto">
            <div className="ui-content">
              <div
                className="accordion-style1 faq-page mb90"
                id="accordionExample"
              >
                {faqs.map((faq, index) => (
                  <div className="accordion" key={faq?.id}>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id={`heading${index}`}>
                        <button
                          className={`accordion-button ${
                            activeIndex === index ? "" : "collapsed"
                          }`}
                          type="button"
                          onClick={() => handleAccordionClick(index)}
                          aria-expanded={
                            activeIndex === index ? "true" : "false"
                          }
                          aria-controls={`collapse${index}`}
                        >
                          {faq.question}
                        </button>
                      </h2>
                      <div
                        id={`collapse${index}`}
                        className={`accordion-collapse collapse ${
                          activeIndex === index ? "show" : ""
                        }`}
                        aria-labelledby={`heading${index}`}
                        data-bs-parent="#accordionExample"
                      >
                        <div
                          className="accordion-body fz16"
                          dangerouslySetInnerHTML={{ __html: faq.answer }}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
