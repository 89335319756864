import React from "react";
import ManagePhotographers from "../../../features/admin/components/ManagePhotographers/ManagePhotographers";
import SideMenu from "../../../shared/SideMenu/SideMenu";
import { UseIsDesktopOrLaptop } from "../../../utils/Responsive/Responsive";

const ManagePhotographersPage = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <div className="dashboard_content_wrapper" style={{ marginTop: "-35px" }}>
      <div className="dashboard dashboard_wrapper pr30 pr0-xl">
        <SideMenu />
        <div
          className={isDesktopOrLaptop ? "dashboard__main" : ""}
          style={{ marginTop: "50px" }}
        >
          <div
            className="dashboard__content hover-bgc-color"
            style={{
              padding: "26px 15px 20px",
              height: "900px",
              backgroundColor: "#fafafa",
            }}
          >
            <div className="row pb40">
              <div className="col-lg-12 pb10">
                <div className="dashboard_title_area mt10">
                  <h5>Manage Photographers</h5>
                  <p className="text"></p>
                </div>
              </div>
              <ManagePhotographers />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePhotographersPage;
