import React, { useEffect, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({
  onChange,
  tabId,
  initialValue,
  handleEditorInit,
  isDisabled = false,
  setError,
  clearErrors,
  label,
}) => {
  const editorRef = useRef(null);
  const charLimit = 3000; // Set your word limit here

  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === "childList") {
          const dialog = document.querySelector(".tox-dialog");
          if (dialog) {
            disableSourceInput();
          }
        }
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Clean up observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  const disableSourceInput = () => {
    const sourceInput = document.querySelector(
      "input[type='url'][role='combobox']"
    );
    if (sourceInput) {
      sourceInput.setAttribute("disabled", "disabled");
      sourceInput.addEventListener("input", (event) => event.preventDefault());
      sourceInput.addEventListener("paste", (event) => event.preventDefault());
    }
  };

  const handleEditorChange = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      const charCount = content.length;
      onChange(tabId, content, charCount);
      // if (charCount <= charLimit) {
      //   // clearErrors(label);
      //   // clearErrors("bio");
      // } else {
      //   // Handle character limit exceeded case
      //   setError(label, {
      //     type: "manual",
      //     message: `Character limit of ${charLimit} exceeded!`,
      //   });
      // }
    }
  };

  return (
    <Editor
      tinymceScriptSrc="https://cdn.tiny.cloud/1/cf8sqm8sabigokrsu80ffmz0skc75sbf6nun6wgbd85eqfmz/tinymce/6/tinymce.min.js"
      onInit={(evt, editor) => {
        editorRef.current = editor;
        handleEditorInit && handleEditorInit(editor);
      }}
      value={initialValue}
      init={{
        height: 500,
        selector: "textarea",
        menubar: false,
        paste_data_images: true,
        relative_urls: true,
        branding: false, // Remove TinyMCE logo
        file_picker_callback: (callback) => {
          const input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", "image/*");
          input.setAttribute("class", "upload_field");
          input.click();
        },
        plugins: [
          "advlist",
          "autolink",
          "lists",
          "link",
          "charmap",
          "anchor",
          "searchreplace",
          "visualblocks",
          "fullscreen",
          "insertdatetime",
          "media",
          "table",
          "preview",
          "wordcount",
          "paste",
        ],
        toolbar:
          "undo redo | blocks | " +
          "bold italic forecolor | alignleft aligncenter " +
          "alignright alignjustify bullist numlist removeformat",
        content_style:
          "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        setup: (editor) => {
          editor.on("input", () => {
            const content = editorRef.current.getContent();
            const charCount = content.length;
            if (charCount <= charLimit) {
              editor.notificationManager.open({
                text: `Char limit of ${charLimit} exceeded!`,
                type: "warning",
                timeout: 3000,
              });
            }
          });
        },
        setup: (editor) => {
          editor.on("init", () => {
            const statusbar =
              editor.theme.panel && editor.theme.panel.find("#statusbar")[0];
            if (statusbar) {
              window.setTimeout(() => {
                statusbar.insert(
                  {
                    type: "label",
                    name: "char-count",
                    text: ["Characters: 0"],
                    classes: "char-count",
                    disabled: editor.settings.readonly,
                  },
                  0
                );
              }, 0);
            }
          });
        },
      }}
      onEditorChange={handleEditorChange}
      disabled={isDisabled}
    />
  );
};

export default TextEditor;
