import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { ErrorToaster, SuccessToaster } from "../../shared/Toasters/toaster";
import { applyJob } from "../../API/PhotographerApi";
import { useNavigate } from "react-router-dom";

const SendProposal = ({
  budgetType,
  showModal,
  setShowProposalModal,
  jobId,
}) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const sendProposal = async (data) => {
    const formValue = {
      jobId,
      budgetType,
      hourlyRate: data.hourlyRate || 0,
      budget: data.budget || 0,
      proposalMessage: data.message,
      estimatedHours: 1,
    };
    try {
      const res = await applyJob(formValue);
      if (res) {
        setShowProposalModal(false);
        SuccessToaster("Successfully Applied for job!");
        setTimeout(() => {
          navigate("/photographer-jobs");
        }, 1000);
      }
    } catch (error) {
      ErrorToaster(error?.message);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={() => setShowProposalModal(false)}
        size="lg"
      >
        <>
          <Modal.Header closeButton>
            <Modal.Title style={{ textTransform: "capitalize" }}>
              Send Proposal
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex align-items-center justify-content-center">
            <form noValidate onSubmit={handleSubmit(sendProposal)}>
              <div className="row">
                {budgetType === "fixed" && (
                  <div className="col-md-12">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb10">
                        Your price
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input
                          id="budget"
                          {...register("budget", {
                            required: "Price is required",
                            maxLength: {
                              value: 5,
                              message: "Budget cannot exceed 5 digits",
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed",
                            },
                          })}
                          type="number"
                          className="form-control"
                          placeholder={`Please enter your budget`}
                          onInput={(e) => {
                            if (e.target.value.length > 5) {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/g,
                                ""
                              ); // Remove non-numeric characters
                              e.target.value = e.target.value.slice(0, 5); // Trim to max length of 3
                            }
                          }}
                        />
                      </div>
                      {errors.budget && (
                        <p className="text-danger fw500">
                          {errors.budget.message}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {budgetType === "hourly" && (
                  <div className="col-md-12">
                    <div className="mb20">
                      <label className="heading-color ff-heading fw500 mb10">
                        Your price
                      </label>
                      <div className="input-group">
                        <span className="input-group-text">$</span>
                        <input
                          id="hourlyRate"
                          {...register("hourlyRate", {
                            required: "Price is required",
                            maxLength: {
                              value: 5,
                              message: "Price cannot exceed 5 digits",
                            },
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed",
                            },
                          })}
                          type="number"
                          className="form-control"
                          placeholder={`Please enter your hourly rate`}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            ); // Remove non-numeric characters
                            if (e.target.value.length > 3) {
                              e.target.value = e.target.value.slice(0, 5); // Trim to max length of 3
                            }
                          }}
                        />
                      </div>
                      {errors.hourlyRate && (
                        <p className="text-danger fw500">
                          {errors.hourlyRate.message}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                {budgetType === "notSure" && (
                  <>
                    <h6 className="widget-title">Not Sure</h6>
                    <p className="text text-capitalize fz14 mt5">
                      To be Discussed with Photographer
                    </p>
                  </>
                )}
                <div className="col-md-12">
                  <div className="mb20">
                    <label className="heading-color ff-heading fw500 mb10">
                      Cover Letter
                    </label>
                    <textarea
                      className="pt15"
                      {...register("message", {
                        required: "Cover Letter is required",
                      })}
                      rows="6"
                      placeholder="Message..."
                    ></textarea>
                    {errors.message && (
                      <p className="text-danger fw500">
                        {errors.message.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="text-start">
                    <Button className="ud-btn btn-thm" type="submit">
                      Submit a Proposal
                      <i className="fal fa-arrow-right-long"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </>
      </Modal>
    </>
  );
};

export default SendProposal;
