import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { ErrorToaster, SuccessToaster } from "../../shared/Toasters/toaster";
import { Link, useNavigate } from "react-router-dom";
import { invitePhotographer, jobListWithoutInvite } from "../../API/JobsApi";
import { Status } from "../../constants/constant";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";
import { createChat } from "../../API/ChatApi";

const JobInviteModal = ({
  photographerId,
  showInviteModal,
  setShowInviteModal,
}) => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const [jobList, setJobList] = useState([]);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const getAllJobList = async () => {
      try {
        const res = await jobListWithoutInvite({ photographerId });
        setJobList(res);
      } catch (error) {
        ErrorToaster(error?.message);
      }
    };
    if (photographerId) {
      getAllJobList();
    }
  }, [photographerId]);

  const createNewChat = async (jobId, message) => {
    try {
      const value = {
        jobId,
        rId: photographerId,
        message: message,
      };
      const { _id } = await createChat(value);
      navigate(`/messages/?${_id}`);
    } catch (error) {
      ErrorToaster(error?.message);
    }
  };

  const sendInvite = async ({ jobId, inviteMessage }) => {
    try {
      await invitePhotographer({ jobId, photographerId, inviteMessage });
      await createNewChat(jobId, inviteMessage);
      SuccessToaster("Invite sent Successfully");
      setShowInviteModal(false);
      setValue("jobId", "");
    } catch (error) {
      ErrorToaster(error?.message);
    }
  };

  return (
    <>
      <Modal
        show={showInviteModal}
        onHide={() => {
          setShowInviteModal(false);
          setValue("jobId", "");
        }}
        size="lg"
      >
        <>
          <Modal.Header closeButton>
            <Modal.Title
              style={{
                textTransform: "capitalize",
                // fontSize: '26px !important',
              }}
            >
              Invite Photographer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex align-items-center justify-content-center">
            <form noValidate className={isDesktopOrLaptop ? "" : "w-100"}>
              <div className="row">
                <p className="text-left fz16 fw-bold">
                  Please select a job for which you wish to invite this
                  photographer. Once the photographer accepts your invitation,
                  you can start chatting with them. If you don't have a job to
                  select, you can create a new one and then try inviting this
                  photographer.
                </p>
                <div
                  className={`col-12 d-flex ${
                    isDesktopOrLaptop ? "" : "flex-column"
                  } align-items-center mt-3`}
                >
                  <label
                    className={`${
                      isDesktopOrLaptop ? "col-2" : "col-3"
                    } heading-color ff-heading fw500 mb-2 fz16`}
                  >
                    Select Job
                    <span className="required-field">*</span>
                  </label>
                  <div
                    className="bootselect-multiselect w-100"
                    style={{
                      height: "50px",
                      padding: "0px 13px",
                    }}
                  >
                    <select
                      className="form-control"
                      {...register("jobId", {
                        required: "JobId is required",
                      })}
                      style={{
                        marginLeft: "10px",
                        height: "40px",
                        padding: "0px 13px",
                        width: "100%",
                      }}
                    >
                      <option value="" selected disabled>
                        Select Job
                      </option>
                      {jobList.map((job) => (
                        <option key={job?._id} value={job?._id}>
                          {job?.title}
                        </option>
                      ))}
                    </select>
                    {errors?.jobId && (
                      <p className="text-danger">{errors.jobId.message}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-12 mt10">
                  <div className="mb10">
                    <label className="heading-color ff-heading fw500 mb10 fz16">
                      Message
                      <span className="required-field">*</span>
                    </label>
                    <textarea
                      {...register("inviteMessage", {
                        required: "Message is required",
                        maxLength: {
                          value: 500,
                          message:
                            "Message length should be less than 500 characters",
                        },
                      })}
                      className="fz16"
                      cols="30"
                      rows="6"
                    />
                    {errors.inviteMessage && (
                      <p className="text-danger">
                        {errors.inviteMessage.message}
                      </p>
                    )}
                  </div>
                </div>
                <div
                  className={
                    isDesktopOrLaptop
                      ? "d-flex justify-content-between -is-activecol-md-12 mt20"
                      : "col-12 d-flex flex-column mt-3"
                  }
                >
                  <div
                    className={`text-end d-flex ${
                      isDesktopOrLaptop ? "" : "w-100 flex-column"
                    } `}
                  >
                    <Button
                      className="ud-btn btn-thm me-2"
                      type="submit"
                      onClick={handleSubmit(sendInvite)}
                    >
                      Send Invitation
                      <i className="fal fa-arrow-right-long"></i>
                    </Button>
                    <Link
                      className={`ud-btn btn-thm me-2 ${
                        isDesktopOrLaptop ? "" : "mt5"
                      }`}
                      to="/create-job"
                      target="_blank"
                    >
                      Create New Job
                      <i className="fal fa-arrow-right-long"></i>
                    </Link>
                    <Button
                      className={`ud-btn btn-thm me-2 ${
                        isDesktopOrLaptop ? "" : "mt-1"
                      }`}
                      type="submit"
                      onClick={() => setShowInviteModal(false)}
                    >
                      Cancel
                      <i className="fal fa-arrow-right-long"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </>
      </Modal>
    </>
  );
};

export default JobInviteModal;
