import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Status, UserTypes } from "../../../../constants/constant";
import { useDispatch, useSelector } from "react-redux";
import {
  GoogleLoginAsync,
  responseMessage,
  responseStatus,
  userSignupAsync,
} from "../../authSlice";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../shared/Toasters/toaster";
import { selectError, clearError, clearStatus } from "../../../auth/authSlice";
import { useEffect } from "react";
import OtpVerificationModal from "../../../../shared/OtpVerification/OtpVerification";
import MapComponent from "../../../../shared/MapComponent/MapComponent";
import { useGoogleLoginHook } from "../../../../utils/hooks/useGoogleLoginHook";
import GoogleButton from "../../../../utils/Icons/GoogleButton/GoogleButton";
import { myDetail } from "../../../../API/UserApi";
import ProfileRegistration from "../../../user/components/ProfileRegistration/ProfileRegistration";

const SignUp = ({ userType }) => {
  const { login: googleLogin, profile } = useGoogleLoginHook();
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState(null);
  const [showMobileSignup, setShowMobileSignup] = useState(false);
  const error = useSelector(selectError);
  const message = useSelector(responseMessage);
  const status = useSelector(responseStatus);
  const [isGoogleSignup, setIsGoogleSignup] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({});

  const userLocation = watch("location");

  const myDetails = async () => {
    setIsLoading(true);
    try {
      const data = await myDetail();
      if (data) {
        data.profileStatus === Status.INCOMPLETE && setShowModal(true);
      }
      setUserInfo(data);
      localStorage.setItem("currentUser", JSON.stringify(data));
      setIsLoading(false);
    } catch (error) {
      console.error("error", error?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (status === "success") {
      if (isGoogleSignup && message === "User Verified") {
        myDetails();
      } else {
        setShow(true);
        SuccessToaster(message);
      }
    }
    if (status === "rejected" && error) {
      ErrorToaster(error);
    }
    return () => {
      dispatch(clearStatus()); // Replace with your action to clear status
      dispatch(clearError()); // Replace with your action to clear error
    };
  }, [status, error, dispatch]);

  const signUp = async (data) => {
    const formData = {
      ...data,
      userType: userType === "job-poster" ? "user" : userType,
    };
    setUserData(formData);
    dispatch(userSignupAsync(formData));
  };

  const handleCloseOTPModal = () => {
    setShow(false);
  };

  useEffect(() => {
    if (!!profile) {
      const formValue = {
        email: profile?.email,
        fullName: profile?.name,
        userType: userType === "job-poster" ? "user" : userType,
      };
      setIsGoogleSignup(true);
      dispatch(GoogleLoginAsync(formValue));
    }
  }, [profile]);

  function getSubHeading(userType) {
    switch (userType) {
      case "photographer":
        return "Create your photographer account to unlock job opportunities and showcase your portfolio.";
      case "job-poster":
        return "Sign up to connect with skilled photographers and post your projects for free.";
      case "agency":
        return "Create your Agency account to unlock job opportunities and showcase your portfolio.";
      default:
        return "Unknown user type. Please provide a valid user type.";
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.profileStatus !== Status.INCOMPLETE) {
      switch (userInfo.userType) {
        case "photographer":
          navigate("/jobs", { replace: true });
          break;
        case "user":
          navigate(state?.redirectUrl ? state?.redirectUrl : "/photographers", {
            replace: true,
          });
          break;
        case "admin":
          navigate("/manage-photographers", { replace: true });
          break;
        case "agency":
          navigate("/jobs", { replace: true });
          break;
        default:
          break;
      }
    }
  }, [userInfo, navigate]);

  return (
    <>
      {show ? (
        <OtpVerificationModal
          userData={userData}
          show={show}
          handleClose={handleCloseOTPModal}
          setShow={setShow}
          // consentData={{
          //   tosConsent: watch('tosConsent'),
          //   shareConsent: watch('shareConsent'),
          // }}
        />
      ) : (
        <>
          {showModal && (
            <ProfileRegistration
              showModal={showModal}
              setShowModal={setShowModal}
            />
          )}
          <div className="body_content" id="container">
            <section className="our-register">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-6 m-auto wow fadeInUp mt-2"
                    data-wow-delay="300ms"
                  >
                    <div className="main-title text-center mb500">
                      <h2
                        className="title text-capitalize"
                        style={{
                          color: "var(--headings-color)",
                          fontFamily: "var(--title-font-family)",
                          lineHeight: 1.3125,
                        }}
                      >
                        Sign Up as a{" "}
                        {userType === "job-poster" ? "Job Poster" : userType}
                      </h2>
                      <p className="paragraph">{getSubHeading(userType)}</p>
                    </div>
                  </div>
                </div>
                <div
                  className="row wow fadeInRight mb200"
                  style={{ marginBottom: "200px" }}
                  data-wow-delay="300ms"
                >
                  <div className="col-xl-6 mx-auto">
                    <div className="log-reg-form search-modal form-style1 bgc-white p50 p30-sm default-box-shadow1 bdrs12">
                      <form noValidate onSubmit={handleSubmit(signUp)}>
                        <div className="mb25">
                          <label
                            htmlFor="fullName"
                            className="form-label fw500 dark-color"
                          >
                            Full Name
                          </label>
                          <input
                            id="fullName"
                            {...register("fullName", {
                              required: "Full Name is required",
                              pattern: {
                                // value: /^[a-zA-Z0-9.,]{1,255}$/gm,
                                message: "Full name not valid",
                              },
                            })}
                            name="fullName"
                            type="text"
                            autoComplete="fullName"
                            required
                            className="form-control"
                            placeholder="Please Enter Your full name"
                          />
                          {errors.fullName && (
                            <p className="text-danger">
                              {errors.fullName.message}
                            </p>
                          )}
                        </div>
                        {!showMobileSignup ? (
                          <div className="mb25">
                            <label
                              htmlFor="email"
                              className="form-label fw500 dark-color"
                            >
                              Email
                            </label>
                            <input
                              id="email"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi,
                                  message: "Email is not valid",
                                },
                              })}
                              type="email"
                              className="form-control"
                              placeholder="Please Enter Your Email"
                            />
                            {errors.email && (
                              <p className="text-danger">
                                {errors.email.message}
                              </p>
                            )}
                          </div>
                        ) : (
                          <div className="mb25">
                            <label
                              htmlFor="phone"
                              className="form-label fw500 dark-color"
                            >
                              Phone
                            </label>
                            <input
                              id="phone"
                              {...register("phone", {
                                required: "Phone is required",
                                maxLength: {
                                  value: 10,
                                  message: "Phone must be 10 digits",
                                },
                              })}
                              type="phone"
                              className="form-control"
                              placeholder="Please Enter Your Phone Number"
                              autoComplete="off"
                            />
                            {errors.phone && (
                              <p className="text-danger">
                                {errors.phone.message}
                              </p>
                            )}
                          </div>
                        )}

                        <div className="mb15">
                          <label
                            htmlFor="password"
                            className="form-label fw500 dark-color"
                          >
                            Password
                          </label>
                          <input
                            id="password"
                            {...register("password", {
                              required: "Password is Required",
                              pattern: {
                                value:
                                  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                                message: `- At least 8 characters\n
                      - must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number\n
                      - Can contain special characters`,
                              },
                            })}
                            type="password"
                            className="form-control"
                            placeholder="*******"
                          />
                          {errors.password && (
                            <p className="text-danger">
                              {errors.password.message}
                            </p>
                          )}
                        </div>
                        {userType === UserTypes.AGENCY && (
                          <div className="mb15">
                            <label
                              className="flex form-label fw500 dark-color"
                              style={{ display: "flex" }}
                            >
                              Employees
                            </label>
                            <input
                              id="employees"
                              {...register("employees", {
                                required: "Employees count is required",
                              })}
                              type="number"
                              className="form-control"
                              placeholder="Please Enter Your Employee Count"
                            />
                            {errors.employees && (
                              <p className="text-danger">
                                {errors.employees.message}
                              </p>
                            )}
                          </div>
                        )}
                        <div className="mb20">
                          <label
                            htmlFor="phone"
                            className="form-label fw500 dark-color"
                          >
                            Location
                          </label>
                          <MapComponent
                            name="location"
                            register={register}
                            setValue={setValue}
                            value={userLocation}
                            required
                            placeholder="Enter Location (USA Only)"
                          />
                          {errors.location && (
                            <p className="text-danger">
                              {errors.location.message}
                            </p>
                          )}
                        </div>
                        {userType !== "job-poster" && (
                          <label className="custom_checkbox fz14 ff-heading">
                            By checking this box, you give us permission to
                            share your portfolio images on our social media
                            platforms.
                            <input
                              type="checkbox"
                              {...register("shareConsent")}
                              defaultChecked="checked"
                            />
                            {errors.shareConsent && (
                              <p className="text-danger">
                                {errors.shareConsent.message}
                              </p>
                            )}
                            <span className="checkmark"></span>
                          </label>
                        )}
                        <label className="custom_checkbox fz14 ff-heading">
                          Yes, I understand and agree to the Topshot
                          Photographer conditions, including the{" "}
                          <Link to="/terms-and-conditions" className="text-thm">
                            Terms And Conditions
                          </Link>{" "}
                          and{" "}
                          <Link to="privacy-policy" className="text-thm">
                            {" "}
                            Privacy Policy
                          </Link>{" "}
                          .
                          <input
                            type="checkbox"
                            {...register("tosConsent", {
                              required:
                                "You must agree to the terms and conditions",
                            })}
                            defaultChecked="checked"
                          />
                          {errors.tosConsent && (
                            <p className="text-danger">
                              {errors.tosConsent.message}
                            </p>
                          )}
                          <span className="checkmark"></span>
                        </label>
                        <div className="form-check mt15">
                          <Link
                            to={`/login/${userType}`}
                            className="text-thm float-end"
                          >
                            Already Have An Account? Sign In.
                          </Link>
                        </div>
                        <div className="d-grid mb20">
                          <button
                            className="ud-btn btn-thm default-box-shadow2"
                            type="submit"
                          >
                            Create An Account{" "}
                            <i className="fal fa-arrow-right-long"></i>
                          </button>
                        </div>
                      </form>
                      {/* <div
                        className="google_login"
                        style={{
                          marginLeft: '80px',
                        }}>
                        <GoogleLogin
                          clientId="877880145583-48se4l9en35vl0t47g847l51s5bau0qg.apps.googleusercontent.com"
                          onSuccess={onSuccess}
                          onFailure={onFailure}
                          cookiePolicy="single_host_origin"
                          buttonText="Sign up with Google"
                          style={{ marginLeft: '140px' }}
                        />
                      </div> */}
                      <div className="d-grid mb20" onClick={googleLogin}>
                        <GoogleButton />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  );
};

export default SignUp;
