import * as _ from 'lodash';

import { axiosWithToken } from '../common/axiosConfig';
import {
  CreateSubscriptionURL,
  CreateCustomerPortalURL,
  CancelSubscriptionURL,
  CreateCustomerURL,
  UpdateSubscriptionURL,
} from '../constants/apiConstants';

export function createCustomer(values) {
  return axiosWithToken.post(CreateCustomerURL, values).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export function createSubscription(values) {
  return axiosWithToken.post(CreateSubscriptionURL, values).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export function updateSubscription(values) {
  return axiosWithToken.post(UpdateSubscriptionURL, values).then((res) => {
    return _.get(res, 'data.data', {});
  });
}

export async function cancelCurrentSubscription() {
  const res = await axiosWithToken.post(CancelSubscriptionURL, {});
  return _.get(res, 'data.data', {});
}

export function createBillingPortal() {
  return axiosWithToken.post(CreateCustomerPortalURL, {}).then((res) => {
    return _.get(res, 'data.data', {});
  });
}
