import React, { useState, useEffect } from "react";
import "./ImageGrid.css"; // Import your CSS file

const ImageGrid = ({ images }) => {
  const [model, setModel] = useState(false);
  const [temImgSrc, setTemImgSrc] = useState("");
  const [currentIndex, setCurrentIndex] = useState(null);

  const handleClick = (imgSrc, index) => {
    setCurrentIndex(index);
    setTemImgSrc(imgSrc);
    setModel(true);
    document.body.style.overflow = "hidden"; // Stop background scroll
  };

  const handleClose = () => {
    setModel(false);
    document.body.style.overflow = "auto"; // Enable background scroll
  };

  const handelRotationRight = () => {
    const totalLength = images?.length;
    if (currentIndex + 1 >= totalLength) {
      setCurrentIndex(0);
      const newUrl = images[0];
      setTemImgSrc(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = images?.filter((item) => images?.indexOf(item) === newIndex);
    const newItem = newUrl[0];
    setTemImgSrc(newItem);
    setCurrentIndex(newIndex);
  };

  const handelRotationLeft = () => {
    const totalLength = images?.length;
    if (currentIndex === 0) {
      setCurrentIndex(totalLength - 1);
      const newUrl = images[totalLength - 1];
      setTemImgSrc(newUrl);
      return;
    }
    const newIndex = currentIndex - 1;
    const newUrl = images?.filter((item) => images?.indexOf(item) === newIndex);
    const newItem = newUrl[0];
    setTemImgSrc(newItem);
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    return () => {
      document.body.style.overflow = "auto"; // Clean up on unmount
    };
  }, []);

  return (
    <>
      <div className={model ? "model open" : "model"}>
        <img src={temImgSrc} alt="Enlarged view" />
        <span
          className="dismiss"
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "30px",
            fontSize: "30px", // Adjust the font size as needed
            cursor: "pointer",
          }}
        >
          <div
            className="sidebar-close-icon"
            style={{
              top: "1px",
              width: "40px",
              height: "38px",
              borderRadius: "50%",
              left: "-16px",
            }}
          >
            <span className="far fa-times"></span>
          </div>
        </span>
        <div onClick={handelRotationLeft} className="overlay-arrows_left">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              style={{ width: "20px", height: "60px", marginLeft: "20px" }}
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </div>
        </div>
        <div onClick={handelRotationRight} className="overlay-arrows_right">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              style={{ width: "20px", height: "60px", marginLeft: "20px" }}
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="gallery mt30">
        {images?.slice(0, 20).map((image, index) => (
          <div
            key={index}
            className="img"
            onClick={() => handleClick(image, index)}
          >
            <img
              src={image}
              style={{ width: "100%", borderRadius: "5px" }}
              alt={`Thumbnail ${index}`}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ImageGrid;
