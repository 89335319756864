import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const ratings = [
  { label: "4★ & above", value: 4 },
  { label: "3★ & above", value: 3 },
  { label: "2★ & above", value: 2 },
  { label: "1★ & above", value: 1 },
];

const Ratings = ({ setFilterRatings }) => {
  const [searchParams] = useSearchParams();

  const [show, setShow] = useState(true);
  const selectedRatings = searchParams.get("ratings");
  const [checkedRatings, setCheckedRatings] = useState([]);

  useEffect(() => {
    if (selectedRatings) {
      const initialSelected = selectedRatings
        .split(",")
        .map((item) => parseInt(item.trim(), 10));
      setCheckedRatings(initialSelected);
    }
  }, [selectedRatings]);

  const handleCheckboxChange = (level) => {
    let updatedCheckedRatings;
    if (checkedRatings.includes(level)) {
      updatedCheckedRatings = checkedRatings.filter(
        (rating) => rating !== level
      );
    } else {
      updatedCheckedRatings = [...checkedRatings, level];
    }
    setCheckedRatings(updatedCheckedRatings);
    setFilterRatings(updatedCheckedRatings); // Update filter with all selected ratings
  };

  return (
    <div className="card mb20 pb5">
      <div className={`${show ? "active" : ""}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0 pt-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}
          >
            Ratings
          </button>
        </h4>
      </div>
      <div
        id="collapse4"
        className={`collapse ${show ? "show" : ""}`}
        aria-labelledby="heading4"
        data-parent="#accordionExample"
      >
        <div className="card-body card-body px-0 pt-0">
          {ratings.map(({ label, value }, index) => (
            <div key={index} className="checkbox-style1">
              <label className="custom_checkbox">
                {label}
                <input
                  type="checkbox"
                  checked={checkedRatings.includes(value)}
                  onChange={() => handleCheckboxChange(value)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ratings;
