import React, { useEffect, useState } from "react";
import { categories } from "../../../dummyData";
import { useSearchParams } from "react-router-dom";

const Category = ({ setFilterSkills, filterSkills }) => {
  const [searchParams] = useSearchParams();
  const allSelectedCategories = searchParams.get("categories");
  const [show, setShow] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState(
    allSelectedCategories
      ?.split(",")
      ?.map((item) => item?.trim())
      ?.filter((item) => item !== "") || []
  );

  useEffect(() => {
    setSelectedCategories(
      allSelectedCategories
        ?.split(",")
        ?.map((item) => item?.trim())
        ?.filter((item) => item !== "") || []
    );
  }, [allSelectedCategories]);

  const handleCheckboxChange = (category) => {
    const updatedCategories = [...selectedCategories];
    if (updatedCategories.includes(category)) {
      const index = updatedCategories.indexOf(category);
      updatedCategories.splice(index, 1);
    } else {
      updatedCategories.push(category);
    }
    setSelectedCategories(updatedCategories);
    setFilterSkills(updatedCategories);
  };

  const handleCheckAllChange = (e) => {
    if (e.target.checked) {
      const allCategories = categories.map((category) => category);
      setSelectedCategories(allCategories);
      setFilterSkills(allCategories);
    } else {
      setSelectedCategories([]);
      setFilterSkills([]);
    }
  };

  return (
    <div className="card mb20 pb10 mt-0">
      <div className={`${show ? "active" : ""}`} id="heading0">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="mb-0">
            <button
              className="btn btn-link ps-0 pt-0"
              type="button"
              onClick={() => setShow((prev) => !prev)}
            >
              Categories
            </button>
          </h4>
          <label className="custom_checkbox mb-0 ms-3">
            Select All
            <input
              type="checkbox"
              id="checkAll"
              checked={selectedCategories.length === categories.length}
              onChange={handleCheckAllChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
      </div>
      <div
        id="collapse0"
        className={`collapse ${show ? "show" : ""}`}
        aria-labelledby="heading0"
        data-parent="#accordionExample"
        style={{ maxHeight: "300px", overflowY: "auto" }}
      >
        <div className="card-body card-body px-0 pt-0">
          {categories?.map((category, index) => (
            <div key={index} className="checkbox-style1 mb10 me-3">
              <label className="custom_checkbox">
                {category}
                <input
                  type="checkbox"
                  checked={selectedCategories?.includes(category)}
                  onChange={() => handleCheckboxChange(category)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
