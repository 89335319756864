export function formatDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date);
  } else {
    return "-";
  }
}

export function oneMonthLater(dateString) {
  const currentDate = new Date(dateString);
  const oneMonthLater = new Date(currentDate);
  oneMonthLater.setMonth(oneMonthLater.getMonth() + 1);
  return oneMonthLater;
}

export function formatTimeFromNow(timestamp = new Date()) {
  const now = new Date();
  const timeDiff = now - new Date(timestamp);

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  if (hours >= 4) {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(timestamp).toLocaleDateString(undefined, options);
  } else if (hours >= 1) {
    return hours + " hrs ago";
  } else if (minutes >= 1) {
    return minutes + " min ago";
  } else {
    return "Just now";
  }
}

export function formatDateWithTime(dateString) {
  const date = new Date(dateString);

  // Array of month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get month, date, year, hours, and minutes
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // Handle midnight (0 hours)

  // Construct the formatted date string
  const formattedDate =
    month + " " + day + ", " + year + ", " + hours + ":" + minutes + " " + ampm;

  return formattedDate;
}
