import React from "react";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";

const MessageAlert = ({ alertType }) => {
  const renderAlertMessage = () => {
    switch (alertType) {
      case "pending for review":
        return "Info: Thank you for submitting your profile on Topshot. We are currently reviewing your profile, and you will receive an update on your registered email account.";
      case "completed":
        return "Congratulations! Your profile has been approved, and we're thrilled to welcome you to our platform. Best of luck on your journey with us! Feel free to start applying for jobs right away. Additionally, check out our subscription plans to upgrade your profile and enjoy numerous perks.";
      case "rejected":
        return "We regret to inform you that your profile has been rejected. We encourage you to review the feedback sent to your registered email account, make the necessary adjustments to your profile, and resubmit it for consideration.";
      default:
        return "";
    }
  };

  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <div
      className="message-alart-style1"
      style={
        isDesktopOrLaptop
          ? { marginTop: "40px", width: "69%", marginLeft: "274px" }
          : {}
      }
    >
      <div
        className="alert alart_style_one alert-dismissible fade show mb20"
        role="alert"
      >
        {" "}
        {renderAlertMessage()}
        {/* <i
          className="far fa-xmark btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></i> */}
      </div>
    </div>
  );
};

export default MessageAlert;
