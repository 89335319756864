import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const additionalInfoOptions = [
  {
    label: "Whether the photographer is insured?",
    value: "isPhotographerInsured",
  },
  {
    label: "Does the photographer have studio space?",
    value: "hasStudioSpace",
  },
];

const AdditionalInfos = ({ setFilterAdditionalInfo }) => {
  const [searchParams] = useSearchParams();

  const [show, setShow] = useState(true);
  const selectedInfo = searchParams.get("additionalInfo");
  const [checkedInfo, setCheckedInfo] = useState([]);

  useEffect(() => {
    if (selectedInfo) {
      const initialSelected = selectedInfo
        .split(",")
        .map((item) => item.trim());
      setCheckedInfo(initialSelected);
    }
  }, [selectedInfo]);

  const handleCheckboxChange = (additionalInfo) => {
    let updatedCheckedInfo;
    if (checkedInfo.includes(additionalInfo)) {
      updatedCheckedInfo = checkedInfo.filter(
        (info) => info !== additionalInfo
      );
    } else {
      updatedCheckedInfo = [...checkedInfo, additionalInfo];
    }
    setCheckedInfo(updatedCheckedInfo);
    setFilterAdditionalInfo(updatedCheckedInfo); // Update filter with all selected options
  };

  return (
    <div className="card mb20 pb5">
      <div className={`${show ? "active" : ""}`} id="heading0">
        <h4>
          <button
            className="btn btn-link ps-0 pt-0"
            type="button"
            onClick={() => setShow((prev) => !prev)}
          >
            Additional Info
          </button>
        </h4>
      </div>
      <div
        id="collapse4"
        className={`collapse ${show ? "show" : ""}`}
        aria-labelledby="heading4"
        data-parent="#accordionExample"
      >
        <div className="card-body card-body px-0 pt-0">
          {additionalInfoOptions.map(({ label, value }, index) => (
            <div key={index} className="checkbox-style1">
              <label className="custom_checkbox">
                {label}
                <input
                  type="checkbox"
                  checked={checkedInfo.includes(value)}
                  onChange={() => handleCheckboxChange(value)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdditionalInfos;
