import React from "react";
import MyJobs from "../../features/job/components/MyJobs";
import SideMenu from "../../shared/SideMenu/SideMenu";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";

const MyJobsPage = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <div className="dashboard_content_wrapper" style={{ marginTop: "-35px" }}>
      <div className="dashboard dashboard_wrapper pr30 pr0-xl">
        <SideMenu />
        <div
          className={isDesktopOrLaptop ? "dashboard__main" : ""}
          style={{ marginTop: isDesktopOrLaptop ? "50px" : "34px" }}
        >
          <div
            className="dashboard__content hover-bgc-color"
            style={{
              padding: ` ${isDesktopOrLaptop ? "26px" : "0px"} 15px 20px`,
              height: "900px",
              backgroundColor: "#fafafa",
            }}
          >
            <div className="row pb40">
              <div className="col-lg-12 pb10">
                <div className="dashboard_title_area">
                  <h5>My Jobs</h5>
                  <p className="text">
                    The ability to edit is limited until a photographer has
                    applied to your job. Once you receive a proposal from a
                    photographer, you will no longer be able to edit or delete
                    the job. However, you can still close the job if you wish.
                    Additionally, you can view all proposals submitted by
                    photographers by clicking Job Details.
                  </p>
                </div>
              </div>
              <MyJobs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyJobsPage;
