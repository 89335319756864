import React, { useState, useEffect, useCallback, useRef } from "react";
import ImageSlider from "../../../shared/ImageSlider/ImageSlider";
import {
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom/dist/index.js";
import { Link } from "react-router-dom";
import Filters from "../../../shared/Filters/Filters";
import { selectAccessToken } from "../../auth/authSlice";
import { useSelector } from "react-redux";
import { photographersList } from "../../../API/PhotographerApi";
import { ErrorToaster } from "../../../shared/Toasters/toaster";
import Loader from "../../../utils/Loader";
import { EmptyComponent } from "../../../shared/Empty/Empty";
import { InformationModal } from "../../../shared/Modal/Modal";
import { Status } from "../../../constants/constant";
import HiddenFilterBar from "../../../shared/HiddenFilterBar/HiddenFilterBar";
import MapComponent from "../../../shared/MapComponent/MapComponent";
import { useForm } from "react-hook-form";
import { UseIsDesktopOrLaptop } from "../../../utils/Responsive/Responsive";
import AllFilterActionButton from "../../../shared/AllFilterActionButton/AllFilterActionButton";
import { getToken } from "../../../common/getToken";
import { AdditionalInfoRenderer } from "../../../utils/AdditionalInfoRenderer";
import { getFirstAndLastLocationParts } from "../../../utils";

const PhotographersListing = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category");
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [photographers, setPhotographers] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterRateType, setFilterRateType] = useState("");
  const [filterLevel, setFilterLevel] = useState("");
  const [filterPrice, setFilterPrice] = useState({ min: 1, max: 1000 });
  const [filterLocation, setFilterLocation] = useState("");
  const [filterAdditionalInfo, setFilterAdditionalInfo] = useState([]);
  const [filterRatings, setFilterRatings] = useState([]);

  const [modalShow, setModalShow] = useState({ data: null, status: false });
  const [showSideBar, setShowSideBar] = useState(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const token = useSelector(selectAccessToken) || getToken();
  const { register, setValue } = useForm();
  const navigate = useNavigate();

  const searchedLocation = location?.state?.location;

  const timerIdRef = useRef();
  const loadFiltersFromLocalStorage = () => {
    const savedFilters = localStorage.getItem("photographerListingFilters");
    if (savedFilters) {
      const params = new URLSearchParams(savedFilters);
      if (params.get("level")) {
        setFilterLevel(params.get("level") || "");
      }
      setFilterRateType(params.get("jobType") || "");
      setFilterPrice({
        min: params.has("minRate") ? Number(params.get("minRate")) : null,
        max: params.has("maxRate") ? Number(params.get("maxRate")) : null,
      });
      const categories = params.get("categories");
      if (categories) {
        const categoryArray = categories
          .split(",")
          .map((item) => item.trim())
          .filter((item) => item !== "");
        setFilterSkills(categoryArray);
      } else {
        setFilterSkills([]);
      }
      if (params.has("location") || searchedLocation) {
        setFilterLocation(params.get("location") ?? searchedLocation);
      }
      // Load additional filters
      const additionalInfo = params.get("additionalInfo")?.split(",") || [];
      setFilterAdditionalInfo(additionalInfo);
      const ratings = params.get("ratings")?.split(",") || [];
      setFilterRatings(ratings);
    }
  };

  const getPhotographersList = useCallback(async () => {
    setStatus(Status.PENDING);
    try {
      const filterData = {
        rateType: filterRateType || "",
        maxRate: filterPrice?.max,
        minRate: filterPrice?.min,
        ...(filterLevel && { level: filterLevel }),
        ...((filterLocation || searchedLocation) && {
          location: filterLocation || searchedLocation,
        }),
        ...(filterSkills.length > 0 && { categories: filterSkills }),
        ...(filterAdditionalInfo.length > 0 && {
          additionalInfo: filterAdditionalInfo,
        }),
        ...(filterRatings.length > 0 && { ratings: filterRatings }),
      };

      if (isInitialRender && category) {
        filterData.categories = [category];
        setIsInitialRender(false);
      }

      const filteredFilterData = Object.fromEntries(
        Object.entries(filterData).filter(([_, value]) => value !== "")
      );

      if (timerIdRef.current) {
        clearTimeout(timerIdRef.current);
      }

      timerIdRef.current = setTimeout(async () => {
        try {
          const data = await photographersList(filteredFilterData);
          setPhotographers(data || []);
          setStatus(Status.IDLE);
          const queryString = new URLSearchParams(
            filteredFilterData
          ).toString();
          localStorage.setItem("photographerListingFilters", queryString);
          navigate(`/photographers?${queryString}`, { replace: true });
        } catch (apiError) {
          console.error("API Error:", apiError?.response?.data?.message);
          setStatus(Status.REJECTED);
          ErrorToaster(apiError?.message);
          setError(apiError?.message);
        }
      }, 500);
    } catch (error) {
      console.error("Error:", error?.response?.data?.message);
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
      setError(error?.message);
    }
  }, [
    filterLevel,
    filterPrice,
    filterRateType,
    filterSkills,
    filterLocation,
    searchedLocation,
    filterAdditionalInfo,
    filterRatings,
    isInitialRender,
    category,
    navigate,
  ]);

  useEffect(() => {
    loadFiltersFromLocalStorage();
  }, []);

  useEffect(() => {
    getPhotographersList();
  }, [
    filterLevel,
    filterPrice,
    filterRateType,
    filterSkills,
    filterLocation,
    searchedLocation,
    filterAdditionalInfo,
    filterRatings,
    getPhotographersList,
  ]);

  const getAllMediaForPhotographer = (portfolioData) => {
    if (filterSkills.length > 0) {
      return portfolioData
        .filter((portfolio) =>
          filterSkills?.some((skill) =>
            portfolio?.category?.toLowerCase()?.includes(skill?.toLowerCase())
          )
        )
        .flatMap((portfolio) => portfolio.media);
    } else {
      return portfolioData.flatMap((portfolio) => portfolio.media);
    }
  };

  return (
    <>
      {showSideBar && (
        <HiddenFilterBar
          setShowSideBar={setShowSideBar}
          showSideBar={showSideBar}
          setFilterSkills={setFilterSkills}
          setFilterPrice={setFilterPrice}
          filterPrice={filterPrice}
          setFilterLevel={setFilterLevel}
          filterLocation={filterLocation}
          setFilterAdditionalInfo={setFilterAdditionalInfo}
          filterAdditionalInfo={filterAdditionalInfo}
          setFilterRatings={setFilterRatings}
          filterRatings={filterRatings}
          type="photographers"
        />
      )}
      <div>
        <InformationModal
          show={modalShow?.status}
          currentData={modalShow?.data}
          onHide={() => setModalShow({ status: false })}
        />
        <div className="hiddenbar-body-ovelay"></div>
        <div className="body_content">
          {/* <!-- Breadcumb Sections --> */}
          {/* <section className="breadcumb-section">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcumb-style1">
                    <div className="breadcumb-list">
                      {!token && <Link to="/">Home</Link>}
                      <Link to="/photographers">Photographers</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          {/* <!-- Listings All Lists --> */}
          <section className="pt30 pb90">
            <div className="container">
              <div className="row">
                <div className="col-lg-3">
                  <Filters
                    setFilterSkills={setFilterSkills}
                    filterSkills={filterSkills}
                    setFilterPrice={setFilterPrice}
                    filterPrice={filterPrice}
                    setFilterLevel={setFilterLevel}
                    filterLevel={filterLevel}
                    // setFilterRateType={setFilterRateType}
                    setFilterLocation={setFilterLocation}
                    filterLocation={filterLocation}
                    setFilterAdditionalInfo={setFilterAdditionalInfo}
                    filterAdditionalInfo={filterAdditionalInfo}
                    setFilterRatings={setFilterRatings}
                    filterRatings={filterRatings}
                    type="photographers"
                  />
                </div>
                <div className="col-lg-9">
                  <div className="row align-items-center mb20">
                    <div className="col-md-6">
                      <div className="text-center text-md-start">
                        <p className="text mb-0 mb10-sm">
                          <span className="fw500">
                            ({photographers.length})
                          </span>{" "}
                          <span className="fz16">Photographers available</span>
                        </p>
                      </div>
                    </div>
                    {!isDesktopOrLaptop && (
                      <div className="col-md-6 col-lg-12">
                        <div className=" p-0 mb-0 text-center">
                          <div className="search_area mb15">
                            <MapComponent
                              name="filterLocation"
                              register={register}
                              setValue={setValue}
                              setFilterLocation={setFilterLocation}
                              value={filterLocation}
                              placeholder="Enter Location (USA Only)"
                            />
                            <label>
                              <span className="flaticon-loupe"></span>
                            </label>
                          </div>
                          <AllFilterActionButton
                            handleClick={() => setShowSideBar((prev) => !prev)}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {status === Status.PENDING ? (
                    <Loader />
                  ) : (
                    <div className="row">
                      {photographers?.map((photographer) => (
                        <div
                          className="col-md-6 col-lg-12"
                          key={photographer._id}
                        >
                          <div className="freelancer-style1 bdr1 hover-box-shadow row ms-0 bdrs12">
                            <div className="col-lg-12 p-0">
                              <div className={`d-lg-flex align-items-center justify-content-lg-between ${isDesktopOrLaptop ? "" : "mb-3"}`}>
                                <div>
                                  <div className="d-lg-flex align-items-center">
                                    <div className="d-flex thumb w90 position-relative rounded-circle mb-3 mb-lg-0">
                                      <div>
                                        <img
                                          className="rounded-circle mx-auto"
                                          style={{
                                            width: "90px",
                                            height: "90px", // Set a fixed height
                                            objectFit: "cover", // Maintain aspect ratio and cover the container
                                            borderRadius: "50%", // Make the image round by setting border radius to 50%
                                            marginBottom: isDesktopOrLaptop
                                              ? "44px"
                                              : "",
                                          }}
                                          src={
                                            photographer?.profilePicture ||
                                            "https://png.pngtree.com/png-vector/20191101/ourmid/pngtree-cartoon-color-simple-male-avatar-png-image_1934459.jpg"
                                          }
                                          alt="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
                                        />
                                      </div>
                                      {/* <span className="online"></span> */}
                                      <div className="ml20 mt10">
                                        <h5
                                          className="title mb-1 "
                                          style={{
                                            width: "max-content",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {photographer?.fullName}
                                        </h5>
                                        {/* <p className="mb-0">UI/UX Designer</p> */}
                                        <p
                                          className="mb-0 fz16"
                                          style={{
                                            width: "max-content",
                                          }}
                                        >
                                          {photographer?.title}
                                        </p>
                                        <div className="review mb5">
                                          <p className="list-inline-item">
                                            <i className="fas fa-star fz10 review-color pr10"></i>
                                            <span className="dark-color fw500">
                                              {photographer?.rating || 0}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      {isDesktopOrLaptop ? (
                                        <div className="ml20 mt10">
                                          <p
                                            className="list-inline-item"
                                            style={{
                                              marginInlineStart: "40px",
                                              marginTop: "19px",
                                            }}
                                          >
                                            <i className="flaticon-place fz16 dark-color pr10"></i>
                                            <span className="dark-color fw500">
                                              {getFirstAndLastLocationParts(
                                                photographer?.location
                                              )}
                                            </span>
                                          </p>
                                          <p className="list-inline-item">
                                            <i className="flaticon-income fz16 dark-color pr5"></i>
                                            <span className="dark-color fw500 fz16">
                                              $
                                              {photographer?.photographerDetails
                                                ?.hourlyRate || 0}{" "}
                                              / hr
                                            </span>
                                          </p>
                                          <p className="list-inline-item">
                                            <i className="fa fa-level-up pr10"></i>
                                            <span className="dark-color fw500  text-capitalize">
                                              {photographer?.photographerDetails
                                                ?.level || "NA"}{" "}
                                            </span>
                                          </p>
                                          <p className="list-inline-item">
                                            <i className="fas fa-history pr10"></i>
                                            <span className="dark-color fw500">
                                              {photographer?.photographerDetails
                                                ?.experience || 0}{" "}
                                              years
                                            </span>
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="col-lg-4 ps-0 ps-xl-3 pe-0">
                                          <div className="details">
                                            <div className="fl-meta d-flex align-items-center justify-content-between">
                                              <h5
                                                className="title mb-1 "
                                                style={{
                                                  width: "max-content",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Location
                                                <br />
                                                <span className="fw400 fz14">
                                                  {getFirstAndLastLocationParts(
                                                    photographer?.location
                                                  )}
                                                </span>
                                              </h5>
                                              <h5
                                                className="title mb-1 "
                                                style={{
                                                  width: "max-content",
                                                  fontWeight: "500",
                                                }}
                                              >
                                                Rate
                                                <br />
                                                <span className="fw400 fz14">
                                                  $
                                                  {photographer
                                                    ?.photographerDetails
                                                    ?.hourlyRate || 0}{" "}
                                                  / hr
                                                </span>
                                              </h5>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                {!!token ? (
                                  <div className="d-grid mt15">
                                    <Link
                                      to={`/photographers/${photographer?._id}`}
                                      // target="_blank"
                                      className="ud-btn btn-light-thm"
                                      style={{
                                        width: isDesktopOrLaptop
                                          ? ""
                                          : "-webkit-fill-available",
                                        padding: isDesktopOrLaptop
                                          ? "16px 50px"
                                          : "13px 35px",
                                        marginTop: isDesktopOrLaptop
                                          ? ""
                                          : "10px",
                                      }}
                                    >
                                      View Profile
                                      <i className="fal fa-arrow-right-long"></i>
                                    </Link>
                                  </div>
                                ) : (
                                  <button
                                    className="ud-btn btn-light-thm"
                                    style={{
                                      width: isDesktopOrLaptop
                                        ? ""
                                        : "-webkit-fill-available",
                                      padding: isDesktopOrLaptop
                                        ? "16px 50px"
                                        : "13px 35px",
                                      marginTop: isDesktopOrLaptop
                                        ? ""
                                        : "10px",
                                    }}
                                    onClick={() =>
                                      setModalShow({
                                        data: photographer?._id,
                                        status: true,
                                      })
                                    }
                                  >
                                    View Profile
                                    <i className="fal fa-arrow-right-long"></i>
                                  </button>
                                )}
                              </div>
                              <div className="skill-tags d-flex flex-wrap align-items-center mb-0">
                                {photographer?.photographerDetails?.skills
                                  .slice(0, 2)
                                  .map((skill, i) => (
                                    <span className="tag mx5 my-2" key={i}>
                                      {skill}
                                    </span>
                                  ))}
                                {photographer?.photographerDetails?.skills
                                  .length > 2 && (
                                  <span className="tag mx5 my-2">
                                    +
                                    {photographer.photographerDetails.skills
                                      .length - 2}{" "}
                                    more
                                  </span>
                                )}
                              </div>
                              {/* <p className="text fz15">
                                {" "}
                                <AdditionalInfoRenderer
                                  text={photographer?.description}
                                  maxLength={150}
                                />
                              </p> */}
                              <div className="col-lg-12 mt25">
                                <ImageSlider
                                  images={getAllMediaForPhotographer(
                                    photographer?.portfolioData
                                  )}
                                  isPhotographer={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {photographers.length === 0 && status === Status.IDLE && (
                    <div className="mt90">
                      <EmptyComponent description="No Data Found." />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default PhotographersListing;
