import Swal from "sweetalert2";

// export function hasMinFiveImages(myPortfolios) {
//   for (const portfolio of myPortfolios) {
//     if (portfolio.media.length >= 5) {
//       return true;
//     }
//   }
//   return false;
// }

export function hasMinFiveImagesInCategory(id, portfolios) {
  for (const portfolio of portfolios) {
    if (portfolio._id === id && portfolio?.media?.length <= 5) {
      return true;
    }
  }
  return false;
}

export function hasMinFiveImages(portfolios, excludedCategory) {
  return portfolios.some(
    (portfolio) =>
      portfolio.category !== excludedCategory && portfolio.media.length >= 5
  );
}

export function getCategoryWarningMessage(isCategoryWarningOpen) {
  let message;

  switch (isCategoryWarningOpen) {
    case "minImgLimit":
      message =
        "You must have at least 5 images in the category. Please upload new images to the category if you want to delete any existing ones.";
      break;
    case "maxCategoryLimit":
      message =
        "Your current plan has reached its category limit. To add more category, please consider upgrading your plan.";
      break;
    case "minImgLimitForPortfolio":
      message =
        "You must have at least 5 images in any one category. Please add at least 5 images in another category before deleting this category.";
      break;
    default:
      message =
        "Your current plan has reached its image upload limit. To upload more images, please consider upgrading your plan.";
  }

  return message;
}

export const LIMIT_TITLES = {
  maxImgLimit: "Upload Limit Reached",
  minImgLimit: "Upload More Images",
  uploadInProgress: "Upload in Progress",
  error: "Error Occurred",
  default: "Information",
  maxCategoryLimit: "Portfolio Limit Reached",
};

export const getDeleteConfirmation = async ({
  title = "Are you sure?",
  description,
  confirmButtonText = "Yes, delete it!",
  cancelButtonText = "No, keep it",
  entityName,
}) => {
  return getConfirmation({
    title,
    description:
      description || `Are you sure you want to delete <b>${entityName}</b> ?`,
    confirmButtonColor: "#5bbb7b",
    cancelButtonColor: "#bed7ed",
    confirmButtonText,
    cancelButtonText,
  });
};

export const getConfirmation = async ({
  title = "Are you sure?",
  description,
  confirmButtonText = "Yes",
  cancelButtonText = "No",
}) => {
  const { isConfirmed } = await Swal.fire({
    title,
    html: description,
    showCancelButton: true,
    confirmButtonColor: "#5bbb7b",
    cancelButtonColor: "#bed7ed",
    confirmButtonText,
    cancelButtonText,
  });

  return isConfirmed;
};

export const getFirstAndLastLocationParts = (location) => {
  if (!location) return "NA";
  const parts = location.split(",").map((part) => part.trim());
  if (parts.length === 1) return parts[0];
  return `${parts[0]}, ${parts[parts.length - 1]}`;
};

export const isEqual = (value1, value2) => value1 === value2;

export const isValidPassword = (password) => {
  const hasLowerCase = /(?=.*[a-z])/.test(password);
  const hasUpperCase = /(?=.*[A-Z])/.test(password);
  const hasDigit = /(?=.*\d)/.test(password);
  const hasSpecialChar = /(?=.*[\W_])/.test(password);

  if (
    !hasLowerCase ||
    !hasUpperCase ||
    !hasDigit ||
    !hasSpecialChar ||
    password.length < 12
  ) {
    return "Please choose a stronger password with at least 12 characters, using letters, numbers, and symbols.";
  }

  return true;
};
