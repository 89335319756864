import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../utils/Dates";
import { ErrorToaster } from "../../../../shared/Toasters/toaster";
import Loader from "../../../../utils/Loader";
import { Status } from "../../../../constants/constant";
import { jobInvitations } from "../../../../API/UserApi";
import { AdditionalInfoRenderer } from "../../../../utils/AdditionalInfoRenderer";
import { EmptyComponent } from "../../../../shared/Empty/Empty";
import { getFirstAndLastLocationParts } from "../../../../utils";

const JobInvites = () => {
  const [jobInvites, setJobInvites] = useState([]);
  const [status, setStatus] = useState("");

  const myJobInvites = async () => {
    setStatus(Status.PENDING);
    try {
      const res = await jobInvitations();
      setJobInvites(res || []);
      setStatus(Status.IDLE);
    } catch (error) {
      setStatus(Status.REJECTED);
      ErrorToaster(error?.message);
    }
  };

  useEffect(() => {
    myJobInvites();
  }, []);

  console.log("kkkkkkkkk", jobInvites);

  return (
    <>
      {status === Status.PENDING ? (
        <Loader />
      ) : (
        <div className="row">
          {jobInvites?.invitations?.map(
            ({ jobId, invitationDate, inviteMessage }) => {
              const {
                title,
                budgetType,
                durationType,
                jobDateTime,
                jobDuration,
                jobEndDateTime,
                jobRate,
                location,
              } = jobInvites?.jobDetails[jobId] || {};
              return (
                <div className="col-md-6 col-lg-12">
                  <div className="freelancer-style1 bdr1 bdrs12 hover-box-shadow row ms-0 align-items-lg-center">
                    <div className="col-lg-8 ps-0 bdrr1 bdrn-xl">
                      <div className="d-lg-flex">
                        {/* <div className="thumb w60 position-relative rounded-circle mb15-md">
                          <img
                            className="rounded-circle mx-auto"
                            src="images/team/client-1.png"
                            alt=""
                          />
                          <span className="online-badge2"></span>
                        </div> */}
                        <div className="details ml15 ml0-md mb15-md">
                          <h5 className="title mb-3">{title}</h5>
                          <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                            <i className="flaticon-place fz16 vam text-thm2 me-1"></i>{" "}
                            {getFirstAndLastLocationParts(location)}
                          </p>
                          <p className="mb-0 fz14 list-inline-item mb5-sm pe-1">
                            <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                            {formatDate(invitationDate)}
                          </p>
                          <p className="mb-0 fz14 list-inline-item mb5-sm">
                            <i className="flaticon-contract fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                            {jobDuration} {durationType}
                          </p>
                          <p className="mb-0 fz14 list-inline-item mb5-sm">
                            <i className="flaticon-30-days fz16 vam text-thm2 me-1 bdrl1 pl15 pl0-xs bdrn-xs"></i>{" "}
                            {formatDate(jobDateTime)} -{" "}
                            {formatDate(jobEndDateTime)}
                          </p>
                        </div>
                      </div>
                      <p className="text mt10">
                        <AdditionalInfoRenderer
                          text={inviteMessage}
                          maxLength={250}
                        />
                      </p>
                    </div>
                    <div className="col-lg-4 ps-0 ps-xl-3 pe-0">
                      <div className="details">
                        <div className="text-lg-end">
                          {budgetType === "notSure" ? (
                            <>
                              <h5 className="widget-title">Not Sure</h5>
                              <p className="text text-capitalize fz14 mt5">
                                To be Discussed with Photographer
                              </p>
                            </>
                          ) : (
                            <>
                              <h4>${jobRate}</h4>
                              <p className="text">{budgetType} Rate</p>
                            </>
                          )}
                        </div>
                        <div className="d-grid mt15">
                          <Link
                            to={`/jobs/${jobId}`}
                            target="_blank"
                            className="ud-btn btn-thm-border bdrs12 hover-default-box-shadow1"
                          >
                            View Details
                            <i className="fal fa-arrow-right-long"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
      {jobInvites?.invitations?.length === 0 && status === Status.IDLE && (
        <div className="mt90">
          <EmptyComponent description="No invites at the moment. Keep your profile updated to attract potential clients!" />
        </div>
      )}
    </>
  );
};

export default JobInvites;
