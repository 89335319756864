import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ImageSlider.css";
import Marquee from "react-fast-marquee";

const ImageSlider = ({ images, isPhotographer }) => {
  return (
    <div className={`${!isPhotographer ? "img-container" : ""}`}>
      <Marquee>
        {images.map((image, index) => (
          <div key={index} className="carousel-item">
            <img
              src={image}
              alt={`${image} ${index}`}
              className={`${
                isPhotographer
                  ? "photographer-carousel-image"
                  : "carousel-image"
              }`}
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default ImageSlider;
