import React from "react";
import SideMenu from "../../shared/SideMenu/SideMenu";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";
import EditJob from "../../features/job/components/EditJob";
import { Link, useNavigate } from "react-router-dom";
import BackIcon from "../../utils/Icons/BackIcon";

const EditJobPage = () => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="dashboard_content_wrapper" style={{ marginTop: "-35px" }}>
      <div className="dashboard dashboard_wrapper pr30 pr0-xl">
        <SideMenu />
        <div
          className={isDesktopOrLaptop ? "dashboard__main" : ""}
          style={{ marginTop: isDesktopOrLaptop ? "50px" : "34px" }}
        >
          <div
            className="dashboard__content hover-bgc-color"
            style={{
              padding: ` ${isDesktopOrLaptop ? "26px" : "0px"} 15px 20px`,
              backgroundColor: "#fafafa",
            }}
          >
            <div className="row pb40">
              <div className="col-lg-12 pb10">
                <div className="dashboard_title_area">
                  <div className="d-flex" onClick={goBack}>
                    <span>
                      <BackIcon />
                    </span>
                    <h5 style={{ marginTop: "5px", marginLeft: "-265px" }}>
                      Edit A Job
                    </h5>
                  </div>
                  <p className="text"></p>
                </div>
              </div>
              <EditJob />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditJobPage;
