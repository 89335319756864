import React from "react";
import Category from "./Category/Category";
import JobType from "./JobType/JobType";
import Price from "./Price/Price";
import Levels from "./Levels/Levels";
import Location from "./Location/Location";
import { UseIsDesktopOrLaptop } from "../../utils/Responsive/Responsive";
import AdditionalInfos from "./AdditionalInfo/AdditionalInfo";
import Ratings from "./Ratings/Ratings";

const Filters = ({
  setFilterSkills,
  filterSkills,
  setFilterPrice,
  filterPrice,
  setFilterLevel,
  setFilterRateType,
  setFilterLocation,
  showSideBar,
  type,
  filterLocation,
  setFilterAdditionalInfo,
  filterAdditionalInfo,
  setFilterRatings,
  filterRatings,
}) => {
  const isDesktopOrLaptop = UseIsDesktopOrLaptop();
  return (
    <div
      className={`col-lg-12 list-sidebar-style1 ${
        !showSideBar && !isDesktopOrLaptop ? "d-none" : "d-lg-block"
      }`}
    >
      <div className="accordion" id="accordionExample">
        <Category
          setFilterSkills={setFilterSkills}
          filterSkills={filterSkills}
        />
        {type !== "photographers" && (
          <JobType setFilterRateType={setFilterRateType} />
        )}
        <Price setFilterPrice={setFilterPrice} filterPrice={filterPrice} />
        {!showSideBar && (
          <Location
            setFilterLocation={setFilterLocation}
            filterType={type}
            filterLocation={filterLocation}
          />
        )}
        {type === "photographers" && (
          <>
            <Levels setFilterLevel={setFilterLevel} />
            <AdditionalInfos
              setFilterAdditionalInfo={setFilterAdditionalInfo}
              filterAdditionalInfo={filterAdditionalInfo}
            />
            <Ratings
              setFilterRatings={setFilterRatings}
              filterRatings={filterRatings}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Filters;
