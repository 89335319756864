// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-container {
  width: 2030px;
  margin-left: -126px;
}
.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  width: 100%;
}

.carousel-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.carousel-image {
  border-radius: 40px; /* Adjust the radius as needed */
  margin-right: 5px;
  width: 350px; /* Increased width */
  height: 400px; /* Increased height */
  background-color: transparent; /* Add margin between images */
}

.photographer-carousel-image {
  border-radius: 10px; /* Adjust the radius as needed */
  margin-right: 5px;
  height: 250px; /* Increased height */
  width: 250px; /* Increased width */
}`, "",{"version":3,"sources":["webpack://./src/shared/ImageSlider/ImageSlider.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB,EAAE,gCAAgC;EACrD,iBAAiB;EACjB,YAAY,EAAE,oBAAoB;EAClC,aAAa,EAAE,qBAAqB;EACpC,6BAA6B,EAAE,8BAA8B;AAC/D;;AAEA;EACE,mBAAmB,EAAE,gCAAgC;EACrD,iBAAiB;EACjB,aAAa,EAAE,qBAAqB;EACpC,YAAY,EAAE,oBAAoB;AACpC","sourcesContent":[".img-container {\n  width: 2030px;\n  margin-left: -126px;\n}\n.carousel-item {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #f0f0f0;\n  padding: 10px;\n  border-radius: 10px;\n  background-color: white;\n  width: 100%;\n}\n\n.carousel-item img {\n  max-width: 100%;\n  max-height: 100%;\n  object-fit: cover;\n}\n\n.carousel-image {\n  border-radius: 40px; /* Adjust the radius as needed */\n  margin-right: 5px;\n  width: 350px; /* Increased width */\n  height: 400px; /* Increased height */\n  background-color: transparent; /* Add margin between images */\n}\n\n.photographer-carousel-image {\n  border-radius: 10px; /* Adjust the radius as needed */\n  margin-right: 5px;\n  height: 250px; /* Increased height */\n  width: 250px; /* Increased width */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
