import React, { useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import InputField from "../../../../shared/InputField/InputField";
import { useForm } from "react-hook-form";
import { isEqual, isValidPassword } from "../../../../utils";
import { updatePassword } from "../../../../API/UserApi";
import {
  ErrorToaster,
  SuccessToaster,
} from "../../../../shared/Toasters/toaster";

const ChangePassword = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const {
    register,
    watch,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    setIsUpdating(true);
    try {
      const res = await updatePassword(data);
      SuccessToaster(res);
      setIsUpdating(false);
      reset();
    } catch (error) {
      setError("oldPassword", { type: "custom", message: error?.message });
      setIsUpdating(false);
      ErrorToaster(error?.message);
    }
  };

  return (
    <div className="ps-widget bgc-white bdrs4 p30 mb30 overflow-hidden position-relative">
      <div className="col-lg-12">
        <Form className="form-style1" onSubmit={handleSubmit(onSubmit)}>
          <Col md={6} className="mt-4">
            <InputField
              name="oldPassword"
              label="Old Password"
              register={register}
              required
              type="text"
              labelClass="heading-color ff-heading fw500 mb10"
              errorMessage="Old password is required"
              error={errors.oldPassword?.message}
            />
          </Col>
          <Col md={6} className="mt-4">
            <InputField
              name="newPassword"
              label="New Password"
              register={register}
              required
              type="text"
              validation={{
                validate: isValidPassword,
              }}
              labelClass="heading-color ff-heading fw500 mb10"
              errorMessage="New password is required"
              error={errors.newPassword?.message}
            />
          </Col>
          <Col md={6} className="mt-4">
            <InputField
              name="confirmPassword"
              label="Confirm Password"
              register={register}
              required
              type="text"
              validation={{
                validate: (value) =>
                  isEqual(value, watch("newPassword")) ||
                  "New Password and Confirm Password should be the same",
              }}
              labelClass="heading-color ff-heading fw500 mb10"
              errorMessage="Confirm password is required"
              error={errors.confirmPassword?.message}
            />
          </Col>

          <div className="col-md-12">
            <div className="text-start">
              <Button
                className="ud-btn btn-thm"
                type="submit"
                disabled={isUpdating}
              >
                Change Password
                <i className="fal fa-arrow-right-long"></i>
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
