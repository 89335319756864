import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Status, UserTypes } from "../../constants/constant";
import { ErrorToaster, SuccessToaster } from "../Toasters/toaster";
import {
  updateInsuranceStatus,
  updateProfileStatus,
  updateReportStatus,
} from "../../API/AdminApi";

export function StatusModal(props) {
  const { show, setShow, userId, type, jobId, reviewId, setReload } = props;
  const [status, setStatus] = useState("");

  const updateUserStatus = async (formData) => {
    setStatus(Status.PENDING);
    try {
      await updateProfileStatus(formData);
      SuccessToaster("Status Updated successfully");
      setShow(false);
      setReload(true);
    } catch (error) {
      console.error("error", error);
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  const updateReviewStatus = async (formData) => {
    setStatus(Status.PENDING);
    try {
      await updateReportStatus(formData);
      SuccessToaster("Status Updated successfully");
      setShow(false);
      setReload(true);
    } catch (error) {
      console.error("error", error);
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  const changeInsuranceStatus = async (formData) => {
    setStatus(Status.PENDING);
    try {
      await updateInsuranceStatus(formData);
      SuccessToaster("Status Updated successfully");
      setShow(false);
      setReload(true);
    } catch (error) {
      console.error("error", error);
      setStatus(Status.REJECTED);
      ErrorToaster("Something went wrong.");
    }
  };

  const { register, handleSubmit, watch } = useForm();

  const onSubmit = async (data) => {
    let formData;

    if (type === "report") {
      formData = {
        jobId: jobId,
        reviewId: reviewId,
        reviewStatus: data.status,
        ...(watch("status") === "rejected" && {
          rejectedReason: data?.message,
        }),
      };
      await updateReviewStatus(formData);
    } else if (type === "insurance") {
      formData = {
        userId: userId,
        verified: data.status === "reject" ? false : true,
        ...(watch("status") === "rejected" && {
          rejectedReason: data?.message,
        }),
      };

      await changeInsuranceStatus(formData);
    } else {
      formData = {
        userId: userId,
        status: data.status,
        ...(watch("status") === "rejected" && { message: data?.message }),
      };
      await updateUserStatus(formData);
    }
  };

  return (
    <Modal
      centered
      size="lg"
      backdrop="static"
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title className="heading-color ff-heading">
          Update Status
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row align-items-center">
            <div className="col-sm-12 d-flex align-items-center">
              <label className="heading-color ff-heading fw500 mb10">
                New Status
              </label>
              <div
                className="bootselect-multiselect"
                style={{
                  height: "50px",
                  padding: "0px 13px 0px",
                }}
              >
                <select
                  className="form-control"
                  {...register("status", {
                    required: "status is required",
                  })}
                  style={{
                    marginLeft: "10px",
                    height: "40px",
                    padding: "0px 13px 0px",
                    width: "350px",
                  }}
                >
                  <option value="" selected disabled>
                    Select Status
                  </option>
                  {["report", UserTypes.PHOTOGRAPHER].includes(type) && (
                    <>
                      <option
                        value={type === "report" ? "approved" : "completed"}
                      >
                        Approve
                      </option>
                      <option value="rejected">Reject</option>
                    </>
                  )}
                  {[UserTypes.JOB_POSTER, UserTypes.PHOTOGRAPHER].includes(
                    type
                  ) && (
                    <>
                      <option value="activate">Activate</option>
                      <option value="deactivate">Deactivate</option>
                    </>
                  )}
                </select>
              </div>
            </div>
          </div>
          {watch("status") === "rejected" && (
            <div className="col-md-12">
              <div className="mb10 mt10">
                <label className="heading-color ff-heading fw500 mb10">
                  Message:
                </label>
                <textarea
                  {...register("message", {
                    required: "message is required",
                  })}
                  cols="10"
                  rows="2"
                  placeholder="Write your reason..."
                />
              </div>
            </div>
          )}
          <div className="modal-footer">
            <button type="submit" className="ud-btn btn-thm feedback">
              Update
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
